@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700|Raleway:300,400|Squada+One|Staatliches);@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);@import url(https://use.typekit.net/bxf1jbh.css);
.wrapperSpinner[data-v-6d631630] {
  width: 100%;
  position: absolute;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.wrapperSpinner .spinner[data-v-6d631630] {
    -webkit-animation: spin-data-v-6d631630 1600ms ease-in-out infinite;
            animation: spin-data-v-6d631630 1600ms ease-in-out infinite;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 110px;
    width: 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.wrapperSpinner svg[data-v-6d631630] {
    -webkit-transform: scale(3);
            transform: scale(3);
}
@-webkit-keyframes spin-data-v-6d631630 {
100% {
    -moz-transform: rotate(360deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
}
@keyframes spin-data-v-6d631630 {
100% {
    -moz-transform: rotate(360deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
}
@keyframes spin-data-v-6d631630 {
100% {
    -webkit-transform: rotate(360deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
}
@keyframes spin-data-v-6d631630 {
100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
}



























































































































































































































































.vdatetime-fade-enter-active,
.vdatetime-fade-leave-active {
  -webkit-transition: opacity .4s;
  transition: opacity .4s;
}

.vdatetime-fade-enter,
.vdatetime-fade-leave-to {
  opacity: 0;
}

.vdatetime-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}

.vdatetime-popup {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 340px;
  max-width: calc(100% - 30px);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .3);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .3);
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.18;
  background: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.vdatetime-popup * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box
}

.vdatetime-popup__header {
  padding: 18px 30px;
  background: #3f51b5;
  color: #fff;
  font-size: 32px;
}

.vdatetime-popup__title {
  margin-bottom: 8px;
  font-size: 21px;
  font-weight: 300;
}

.vdatetime-popup__year {
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  -webkit-transition: opacity .3s;
  transition: opacity .3s
}

.vdatetime-popup__year:hover {
    opacity: 1
}

.vdatetime-popup__date {
  line-height: 1;
  cursor: pointer;
}

.vdatetime-popup__actions {
  padding: 0 20px 10px 30px;
  text-align: right;
}

.vdatetime-popup__actions__button {
  display: inline-block;
  border: none;
  padding: 10px 20px;
  background: transparent;
  font-size: 16px;
  color: #3f51b5;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s
}

.vdatetime-popup__actions__button:hover {
    color: #444
}

.vdatetime-calendar__navigation--previous:hover svg path, .vdatetime-calendar__navigation--next:hover svg path {
    stroke: #888;
}

.vdatetime-calendar__navigation,
.vdatetime-calendar__navigation * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.vdatetime-calendar__navigation {
  position: relative;
  margin: 15px 0;
  padding: 0 30px;
  width: 100%;
}

.vdatetime-calendar__navigation--previous,
.vdatetime-calendar__navigation--next {
  position: absolute;
  top: 0;
  padding: 0 5px;
  width: 18px;
  cursor: pointer
}

.vdatetime-calendar__navigation--previous svg, .vdatetime-calendar__navigation--next svg {
    width: 8px;
}

.vdatetime-calendar__navigation--previous svg path, .vdatetime-calendar__navigation--next svg path {
      -webkit-transition: stroke .3s;
      transition: stroke .3s;
}

.vdatetime-calendar__navigation--previous {
  left: 25px;
}

.vdatetime-calendar__navigation--next {
  right: 25px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.vdatetime-calendar__current--month {
  text-align: center;
  text-transform: capitalize;
}

.vdatetime-calendar__month {
  padding: 0 20px;
  -webkit-transition: height .2s;
  transition: height .2s;
}

.vdatetime-calendar__month__weekday,
.vdatetime-calendar__month__day {
  display: inline-block;
  width: 14.28571%;
  line-height: 36px;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer
}

.vdatetime-calendar__month__weekday > span, .vdatetime-calendar__month__day > span {
    display: block;
    width: 100%;
    position: relative;
    height: 0;
    padding: 0 0 100%;
    overflow: hidden;
}

.vdatetime-calendar__month__weekday > span > span, .vdatetime-calendar__month__day > span > span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 0;
      border-radius: 50%;
      -webkit-transition: background-color .3s, color .3s;
      transition: background-color .3s, color .3s;
}

.vdatetime-calendar__month__weekday {
  font-weight: bold;
}

.vdatetime-calendar__month__day:hover > span > span {
  background: #eee;
}

.vdatetime-calendar__month__day--selected {
}

.vdatetime-calendar__month__day--selected > span > span,
  .vdatetime-calendar__month__day--selected:hover > span > span {
    color: #fff;
    background: #3f51b5;
}

.vdatetime-calendar__month__day--disabled {
  opacity: 0.4;
  cursor: default
}

.vdatetime-calendar__month__day--disabled:hover > span > span {
    color: inherit;
    background: transparent;
}

.vdatetime-time-picker__list::-webkit-scrollbar-thumb {
    background: #ccc
}

.vdatetime-time-picker__list::-webkit-scrollbar-track {
    background: #efefef
}

.vdatetime-time-picker * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box
}

.vdatetime-time-picker {
  -webkit-box-sizing: border-box;
          box-sizing: border-box
}

.vdatetime-time-picker::after {
    content: '';
    display: table;
    clear: both
}

.vdatetime-time-picker__list {
  float: left;
  width: 50%;
  height: 305px;
  overflow-y: scroll
}

.vdatetime-time-picker__list::-webkit-scrollbar {
    width: 3px
}

.vdatetime-time-picker__with-suffix .vdatetime-time-picker__list {
  width: 33.3%;
}

.vdatetime-time-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: font-size .3s;
  transition: font-size .3s;
}

.vdatetime-time-picker__item:hover {
  font-size: 32px;
}

.vdatetime-time-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}

.vdatetime-time-picker__item--disabled {
  opacity: 0.4;
  cursor: default;
  font-size: 20px !important;
}

.vdatetime-year-picker__list::-webkit-scrollbar-thumb {
    background: #ccc
}

.vdatetime-year-picker__list::-webkit-scrollbar-track {
    background: #efefef
}

.vdatetime-year-picker * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box
}

.vdatetime-year-picker {
  -webkit-box-sizing: border-box;
          box-sizing: border-box
}

.vdatetime-year-picker::after {
    content: '';
    display: table;
    clear: both
}

.vdatetime-year-picker__list {
  float: left;
  width: 100%;
  height: 305px;
  overflow-y: scroll
}

.vdatetime-year-picker__list::-webkit-scrollbar {
    width: 3px
}

.vdatetime-year-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: font-size .3s;
  transition: font-size .3s;
}

.vdatetime-year-picker__item:hover {
  font-size: 32px;
}

.vdatetime-year-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}

.vdatetime-year-picker__item--disabled {
  opacity: 0.4;
  cursor: default
}

.vdatetime-year-picker__item--disabled:hover {
    color: inherit;
    background: transparent
}

.vdatetime-month-picker__list::-webkit-scrollbar-thumb {
    background: #ccc
}

.vdatetime-month-picker__list::-webkit-scrollbar-track {
    background: #efefef
}

.vdatetime-month-picker * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box
}

.vdatetime-month-picker {
  -webkit-box-sizing: border-box;
          box-sizing: border-box
}

.vdatetime-month-picker::after {
    content: '';
    display: table;
    clear: both
}

.vdatetime-month-picker__list {
  float: left;
  width: 100%;
  height: 305px;
  overflow-y: scroll
}

.vdatetime-month-picker__list::-webkit-scrollbar {
    width: 3px
}

.vdatetime-month-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: font-size .3s;
  transition: font-size .3s;
}

.vdatetime-month-picker__item:hover {
  font-size: 32px;
}

.vdatetime-month-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}

.vdatetime-month-picker__item--disabled {
  opacity: 0.4;
  cursor: default
}

.vdatetime-month-picker__item--disabled:hover {
    color: inherit;
    background: transparent
}
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #63c2de;
  --white: #fff;
  --gray: #9fa1a3;
  --gray-dark: #2a2c36;
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #63c2de;
  --white: #fff;
  --gray: #9fa1a3;
  --primary: #f6174d;
  --secondary: #30323d;
  --success: #4dbd74;
  --info: #63c2de;
  --warning: #f8cb00;
  --danger: #f86c6b;
  --light: #f8f9fa;
  --dark: #2a2c36;
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #63c2de;
  --gray-100: #f8f9fa;
  --gray-200: #d1d4d7;
  --gray-300: #818a91;
  --gray-400: #869fac;
  --gray-500: #678898;
  --gray-600: #9fa1a3;
  --gray-700: #3e515b;
  --gray-800: #2a2c36;
  --gray-900: #151b1e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
@-ms-viewport {
  width: device-width; }
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #151b1e;
  text-align: left;
  background-color: #e4e5e6; }
[tabindex="-1"]:focus {
  outline: 0 !important; }
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }
p {
  margin-top: 0;
  margin-bottom: 1rem; }
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }
dt {
  font-weight: 700; }
dd {
  margin-bottom: .5rem;
  margin-left: 0; }
blockquote {
  margin: 0 0 1rem; }
dfn {
  font-style: italic; }
b,
strong {
  font-weight: bolder; }
small {
  font-size: 80%; }
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }
sub {
  bottom: -.25em; }
sup {
  top: -.5em; }
a {
  color: #f6174d;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
a:hover {
    color: #b90732;
    text-decoration: underline; }
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
a:not([href]):not([tabindex]):focus {
    outline: 0; }
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }
figure {
  margin: 0 0 1rem; }
img {
  vertical-align: middle;
  border-style: none; }
svg {
  overflow: hidden;
  vertical-align: middle; }
table {
  border-collapse: collapse; }
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #9fa1a3;
  text-align: left;
  caption-side: bottom; }
th {
  text-align: inherit; }
label {
  display: inline-block;
  margin-bottom: 0.5rem; }
button {
  border-radius: 0; }
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }
button,
input {
  overflow: visible; }
button,
select {
  text-transform: none; }
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }
input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }
textarea {
  overflow: auto;
  resize: vertical; }
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
progress {
  vertical-align: baseline; }
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }
output {
  display: inline-block; }
summary {
  display: list-item;
  cursor: pointer; }
template {
  display: none; }
[hidden] {
  display: none !important; }
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }
h1, .h1 {
  font-size: 2.1875rem; }
h2, .h2 {
  font-size: 1.75rem; }
h3, .h3 {
  font-size: 1.53125rem; }
h4, .h4 {
  font-size: 1.3125rem; }
h5, .h5 {
  font-size: 1.09375rem; }
h6, .h6 {
  font-size: 0.875rem; }
.lead {
  font-size: 1.09375rem;
  font-weight: 300; }
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
small,
.small {
  font-size: 80%;
  font-weight: 400; }
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }
.list-unstyled {
  padding-left: 0;
  list-style: none; }
.list-inline {
  padding-left: 0;
  list-style: none; }
.list-inline-item {
  display: inline-block; }
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }
.initialism {
  font-size: 90%;
  text-transform: uppercase; }
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #9fa1a3; }
.blockquote-footer::before {
    content: "\2014   \A0"; }
.img-fluid {
  max-width: 100%;
  height: auto; }
.img-thumbnail {
  padding: 0.25rem;
  background-color: #e4e5e6;
  border: 1px solid #818a91;
  max-width: 100%;
  height: auto; }
.figure {
  display: inline-block; }
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }
.figure-caption {
  font-size: 90%;
  color: #9fa1a3; }
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
a > code {
    color: inherit; }
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #151b1e; }
kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }
pre {
  display: block;
  font-size: 87.5%;
  color: #151b1e; }
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
@media (min-width: 576px) {
    .container {
      max-width: 540px; } }
@media (min-width: 768px) {
    .container {
      max-width: 720px; } }
@media (min-width: 992px) {
    .container {
      max-width: 960px; } }
@media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
.no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }
.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none; }
.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }
.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }
.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }
.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }
.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }
.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }
.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }
.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }
.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }
.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }
.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }
.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }
.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }
.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }
.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }
.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }
.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }
.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }
.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }
.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }
.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }
.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }
.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }
.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }
.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }
.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }
.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }
.offset-1 {
  margin-left: 8.33333%; }
.offset-2 {
  margin-left: 16.66667%; }
.offset-3 {
  margin-left: 25%; }
.offset-4 {
  margin-left: 33.33333%; }
.offset-5 {
  margin-left: 41.66667%; }
.offset-6 {
  margin-left: 50%; }
.offset-7 {
  margin-left: 58.33333%; }
.offset-8 {
  margin-left: 66.66667%; }
.offset-9 {
  margin-left: 75%; }
.offset-10 {
  margin-left: 83.33333%; }
.offset-11 {
  margin-left: 91.66667%; }
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
.table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #818a91; }
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #818a91; }
.table tbody + tbody {
    border-top: 2px solid #818a91; }
.table .table {
    background-color: #e4e5e6; }
.table-sm th,
.table-sm td {
  padding: 0.3rem; }
.table-bordered {
  border: 1px solid #818a91; }
.table-bordered th,
  .table-bordered td {
    border: 1px solid #818a91; }
.table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fcbecd; }
.table-hover .table-primary:hover {
  background-color: #fba6ba; }
.table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fba6ba; }
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c5c6c9; }
.table-hover .table-secondary:hover {
  background-color: #b8b9bd; }
.table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b8b9bd; }
.table-success,
.table-success > th,
.table-success > td {
  background-color: #cdedd8; }
.table-hover .table-success:hover {
  background-color: #bae6c9; }
.table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bae6c9; }
.table-info,
.table-info > th,
.table-info > td {
  background-color: #d3eef6; }
.table-hover .table-info:hover {
  background-color: #bee6f2; }
.table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #bee6f2; }
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fdf0b8; }
.table-hover .table-warning:hover {
  background-color: #fceb9f; }
.table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fceb9f; }
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fdd6d6; }
.table-hover .table-danger:hover {
  background-color: #fcbebe; }
.table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fcbebe; }
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }
.table-hover .table-light:hover {
  background-color: #ececf6; }
.table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c3c4c7; }
.table-hover .table-dark:hover {
  background-color: #b6b7bb; }
.table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b6b7bb; }
.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #c1e7f4; }
.table-hover .table-blue:hover {
  background-color: #abdff0; }
.table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #abdff0; }
.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #d4bcfb; }
.table-hover .table-indigo:hover {
  background-color: #c5a4fa; }
.table-hover .table-indigo:hover > td,
  .table-hover .table-indigo:hover > th {
    background-color: #c5a4fa; }
.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d7caee; }
.table-hover .table-purple:hover {
  background-color: #c8b7e8; }
.table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #c8b7e8; }
.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #f9c9df; }
.table-hover .table-pink:hover {
  background-color: #f6b2d1; }
.table-hover .table-pink:hover > td,
  .table-hover .table-pink:hover > th {
    background-color: #f6b2d1; }
.table-red,
.table-red > th,
.table-red > td {
  background-color: #fdd6d6; }
.table-hover .table-red:hover {
  background-color: #fcbebe; }
.table-hover .table-red:hover > td,
  .table-hover .table-red:hover > th {
    background-color: #fcbebe; }
.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #fdf0b8; }
.table-hover .table-orange:hover {
  background-color: #fceb9f; }
.table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #fceb9f; }
.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #ffeeba; }
.table-hover .table-yellow:hover {
  background-color: #ffe8a1; }
.table-hover .table-yellow:hover > td,
  .table-hover .table-yellow:hover > th {
    background-color: #ffe8a1; }
.table-green,
.table-green > th,
.table-green > td {
  background-color: #cdedd8; }
.table-hover .table-green:hover {
  background-color: #bae6c9; }
.table-hover .table-green:hover > td,
  .table-hover .table-green:hover > th {
    background-color: #bae6c9; }
.table-teal,
.table-teal > th,
.table-teal > td {
  background-color: #c1f0e2; }
.table-hover .table-teal:hover {
  background-color: #acebd8; }
.table-hover .table-teal:hover > td,
  .table-hover .table-teal:hover > th {
    background-color: #acebd8; }
.table-cyan,
.table-cyan > th,
.table-cyan > td {
  background-color: #d3eef6; }
.table-hover .table-cyan:hover {
  background-color: #bee6f2; }
.table-hover .table-cyan:hover > td,
  .table-hover .table-cyan:hover > th {
    background-color: #bee6f2; }
.table-gray-100,
.table-gray-100 > th,
.table-gray-100 > td {
  background-color: #fdfdfe; }
.table-hover .table-gray-100:hover {
  background-color: #ececf6; }
.table-hover .table-gray-100:hover > td,
  .table-hover .table-gray-100:hover > th {
    background-color: #ececf6; }
.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #f2f3f4; }
.table-hover .table-gray-200:hover {
  background-color: #e4e6e8; }
.table-hover .table-gray-200:hover > td,
  .table-hover .table-gray-200:hover > th {
    background-color: #e4e6e8; }
.table-gray-300,
.table-gray-300 > th,
.table-gray-300 > td {
  background-color: #dcdee0; }
.table-hover .table-gray-300:hover {
  background-color: #ced1d4; }
.table-hover .table-gray-300:hover > td,
  .table-hover .table-gray-300:hover > th {
    background-color: #ced1d4; }
.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #dde4e8; }
.table-hover .table-gray-400:hover {
  background-color: #ced8de; }
.table-hover .table-gray-400:hover > td,
  .table-hover .table-gray-400:hover > th {
    background-color: #ced8de; }
.table-gray-500,
.table-gray-500 > th,
.table-gray-500 > td {
  background-color: #d4dee2; }
.table-hover .table-gray-500:hover {
  background-color: #c5d2d8; }
.table-hover .table-gray-500:hover > td,
  .table-hover .table-gray-500:hover > th {
    background-color: #c5d2d8; }
.table-gray-600,
.table-gray-600 > th,
.table-gray-600 > td {
  background-color: #e4e5e5; }
.table-hover .table-gray-600:hover {
  background-color: #d7d8d8; }
.table-hover .table-gray-600:hover > td,
  .table-hover .table-gray-600:hover > th {
    background-color: #d7d8d8; }
.table-gray-700,
.table-gray-700 > th,
.table-gray-700 > td {
  background-color: #c9ced1; }
.table-hover .table-gray-700:hover {
  background-color: #bbc2c5; }
.table-hover .table-gray-700:hover > td,
  .table-hover .table-gray-700:hover > th {
    background-color: #bbc2c5; }
.table-gray-800,
.table-gray-800 > th,
.table-gray-800 > td {
  background-color: #c3c4c7; }
.table-hover .table-gray-800:hover {
  background-color: #b6b7bb; }
.table-hover .table-gray-800:hover > td,
  .table-hover .table-gray-800:hover > th {
    background-color: #b6b7bb; }
.table-gray-900,
.table-gray-900 > th,
.table-gray-900 > td {
  background-color: #bdbfc0; }
.table-hover .table-gray-900:hover {
  background-color: #b0b2b4; }
.table-hover .table-gray-900:hover > td,
  .table-hover .table-gray-900:hover > th {
    background-color: #b0b2b4; }
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
.table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }
.table .thead-dark th {
  color: #e4e5e6;
  background-color: #151b1e;
  border-color: #252f35; }
.table .thead-light th {
  color: #3e515b;
  background-color: #d1d4d7;
  border-color: #818a91; }
.table-dark {
  color: #e4e5e6;
  background-color: #151b1e; }
.table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #252f35; }
.table-dark.table-bordered {
    border: 0; }
.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
.table-responsive > .table-bordered {
    border: 0; }
.form-control {
  display: block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #3e515b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d4d7;
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
@media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
.form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
.form-control:focus {
    color: #3e515b;
    background-color: #fff;
    border-color: #fb92ab;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.25); }
.form-control::-webkit-input-placeholder {
    color: #9fa1a3;
    opacity: 1; }
.form-control::-ms-input-placeholder {
    color: #9fa1a3;
    opacity: 1; }
.form-control::placeholder {
    color: #9fa1a3;
    opacity: 1; }
.form-control:disabled, .form-control[readonly] {
    background-color: #d1d4d7;
    opacity: 1; }
select.form-control:focus::-ms-value {
  color: #3e515b;
  background-color: #fff; }
.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76562rem;
  line-height: 1.5; }
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #151b1e;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }
.form-control-sm {
  height: calc(1.64844rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }
.form-control-lg {
  height: calc(2.64062rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5; }
select.form-control[size], select.form-control[multiple] {
  height: auto; }
textarea.form-control {
  height: auto; }
.form-group {
  margin-bottom: 1rem; }
.form-text {
  display: block;
  margin-top: 0.25rem; }
.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
.form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
.form-check-input:disabled ~ .form-check-label {
    color: #9fa1a3; }
.form-check-label {
  margin-bottom: 0; }
.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4dbd74; }
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(77, 189, 116, 0.9); }
.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #4dbd74; }
.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #4dbd74;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25); }
.was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4dbd74; }
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4dbd74; }
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #aadfbd; }
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #72cb91; }
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(77, 189, 116, 0.25);
          box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(77, 189, 116, 0.25); }
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4dbd74; }
.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25); }
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b; }
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(248, 108, 107, 0.9); }
.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #f86c6b; }
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #f86c6b;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25); }
.was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f86c6b; }
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f86c6b; }
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #fee5e5; }
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #fa9c9c; }
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(248, 108, 107, 0.25);
          box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(248, 108, 107, 0.25); }
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f86c6b; }
.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25); }
.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
.form-inline .form-check {
    width: 100%; }
@media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
@media screen and (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
.btn:hover, .btn:focus {
    text-decoration: none; }
.btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.25); }
.btn.disabled, .btn:disabled {
    opacity: 0.65; }
.btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }
.btn-primary {
  color: #fff;
  background-color: #f6174d;
  border-color: #f6174d; }
.btn-primary:hover {
    color: #fff;
    background-color: #de093c;
    border-color: #d20839; }
.btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.5); }
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #f6174d;
    border-color: #f6174d; }
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #d20839;
    border-color: #c60836; }
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.5); }
.btn-secondary {
  color: #fff;
  background-color: #30323d;
  border-color: #30323d; }
.btn-secondary:hover {
    color: #fff;
    background-color: #1f2028;
    border-color: #1a1b20; }
.btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 50, 61, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(48, 50, 61, 0.5); }
.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #30323d;
    border-color: #30323d; }
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1a1b20;
    border-color: #141519; }
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 50, 61, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(48, 50, 61, 0.5); }
.btn-success {
  color: #fff;
  background-color: #4dbd74;
  border-color: #4dbd74; }
.btn-success:hover {
    color: #fff;
    background-color: #3ea662;
    border-color: #3a9d5d; }
.btn-success:focus, .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3a9d5d;
    border-color: #379457; }
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
.btn-info {
  color: #151b1e;
  background-color: #63c2de;
  border-color: #63c2de; }
.btn-info:hover {
    color: #151b1e;
    background-color: #43b6d7;
    border-color: #39b2d5; }
.btn-info:focus, .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
.btn-info.disabled, .btn-info:disabled {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #39b2d5;
    border-color: #2eadd3; }
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
.btn-warning {
  color: #151b1e;
  background-color: #f8cb00;
  border-color: #f8cb00; }
.btn-warning:hover {
    color: #151b1e;
    background-color: #d2ac00;
    border-color: #c5a100; }
.btn-warning:focus, .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
.btn-warning.disabled, .btn-warning:disabled {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #151b1e;
    background-color: #c5a100;
    border-color: #b89700; }
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
.btn-danger {
  color: #fff;
  background-color: #f86c6b;
  border-color: #f86c6b; }
.btn-danger:hover {
    color: #fff;
    background-color: #f64846;
    border-color: #f63c3a; }
.btn-danger:focus, .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f63c3a;
    border-color: #f5302e; }
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
.btn-light {
  color: #151b1e;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
.btn-light:hover {
    color: #151b1e;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
.btn-light:focus, .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-light.disabled, .btn-light:disabled {
    color: #151b1e;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #151b1e;
    background-color: #dae0e5;
    border-color: #d3d9df; }
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-dark {
  color: #fff;
  background-color: #2a2c36;
  border-color: #2a2c36; }
.btn-dark:hover {
    color: #fff;
    background-color: #191a20;
    border-color: #141519; }
.btn-dark:focus, .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5); }
.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #2a2c36;
    border-color: #2a2c36; }
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #141519;
    border-color: #0e0f12; }
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5); }
.btn-blue {
  color: #fff;
  background-color: #20a8d8;
  border-color: #20a8d8; }
.btn-blue:hover {
    color: #fff;
    background-color: #1b8eb7;
    border-color: #1985ac; }
.btn-blue:focus, .btn-blue.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }
.btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #1985ac;
    border-color: #187da0; }
.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }
.btn-indigo {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2; }
.btn-indigo:hover {
    color: #fff;
    background-color: #560bd0;
    border-color: #510bc4; }
.btn-indigo:focus, .btn-indigo.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }
.btn-indigo.disabled, .btn-indigo:disabled {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #510bc4;
    border-color: #4c0ab8; }
.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-indigo.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }
.btn-purple {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1; }
.btn-purple:hover {
    color: #fff;
    background-color: #5e37a6;
    border-color: #59339d; }
.btn-purple:focus, .btn-purple.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }
.btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #59339d;
    border-color: #533093; }
.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }
.btn-pink {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c; }
.btn-pink:hover {
    color: #fff;
    background-color: #e41c78;
    border-color: #d91a72; }
.btn-pink:focus, .btn-pink.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
.btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #d91a72;
    border-color: #ce196c; }
.btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
.btn-red {
  color: #fff;
  background-color: #f86c6b;
  border-color: #f86c6b; }
.btn-red:hover {
    color: #fff;
    background-color: #f64846;
    border-color: #f63c3a; }
.btn-red:focus, .btn-red.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
.btn-red.disabled, .btn-red:disabled {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
.btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
  .show > .btn-red.dropdown-toggle {
    color: #fff;
    background-color: #f63c3a;
    border-color: #f5302e; }
.btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-red.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
.btn-orange {
  color: #151b1e;
  background-color: #f8cb00;
  border-color: #f8cb00; }
.btn-orange:hover {
    color: #151b1e;
    background-color: #d2ac00;
    border-color: #c5a100; }
.btn-orange:focus, .btn-orange.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
.btn-orange.disabled, .btn-orange:disabled {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
.btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #151b1e;
    background-color: #c5a100;
    border-color: #b89700; }
.btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
.btn-yellow {
  color: #151b1e;
  background-color: #ffc107;
  border-color: #ffc107; }
.btn-yellow:hover {
    color: #151b1e;
    background-color: #e0a800;
    border-color: #d39e00; }
.btn-yellow:focus, .btn-yellow.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
.btn-yellow.disabled, .btn-yellow:disabled {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
.btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow.dropdown-toggle {
    color: #151b1e;
    background-color: #d39e00;
    border-color: #c69500; }
.btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
.btn-green {
  color: #fff;
  background-color: #4dbd74;
  border-color: #4dbd74; }
.btn-green:hover {
    color: #fff;
    background-color: #3ea662;
    border-color: #3a9d5d; }
.btn-green:focus, .btn-green.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
.btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
.btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #3a9d5d;
    border-color: #379457; }
.btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997; }
.btn-teal:hover {
    color: #fff;
    background-color: #1ba87e;
    border-color: #199d76; }
.btn-teal:focus, .btn-teal.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }
.btn-teal.disabled, .btn-teal:disabled {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
.btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
  .show > .btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #199d76;
    border-color: #17926e; }
.btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-teal.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }
.btn-cyan {
  color: #151b1e;
  background-color: #63c2de;
  border-color: #63c2de; }
.btn-cyan:hover {
    color: #151b1e;
    background-color: #43b6d7;
    border-color: #39b2d5; }
.btn-cyan:focus, .btn-cyan.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
.btn-cyan.disabled, .btn-cyan:disabled {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
.btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-cyan.dropdown-toggle {
    color: #fff;
    background-color: #39b2d5;
    border-color: #2eadd3; }
.btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cyan.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
.btn-gray-100 {
  color: #151b1e;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
.btn-gray-100:hover {
    color: #151b1e;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
.btn-gray-100:focus, .btn-gray-100.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #151b1e;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
.btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #151b1e;
    background-color: #dae0e5;
    border-color: #d3d9df; }
.btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-gray-200 {
  color: #151b1e;
  background-color: #d1d4d7;
  border-color: #d1d4d7; }
.btn-gray-200:hover {
    color: #151b1e;
    background-color: #bdc1c5;
    border-color: #b6bbbf; }
.btn-gray-200:focus, .btn-gray-200.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 212, 215, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(209, 212, 215, 0.5); }
.btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #151b1e;
    background-color: #d1d4d7;
    border-color: #d1d4d7; }
.btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #151b1e;
    background-color: #b6bbbf;
    border-color: #afb4b9; }
.btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 212, 215, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(209, 212, 215, 0.5); }
.btn-gray-300 {
  color: #fff;
  background-color: #818a91;
  border-color: #818a91; }
.btn-gray-300:hover {
    color: #fff;
    background-color: #6e777e;
    border-color: #687077; }
.btn-gray-300:focus, .btn-gray-300.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 138, 145, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(129, 138, 145, 0.5); }
.btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #fff;
    background-color: #818a91;
    border-color: #818a91; }
.btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #fff;
    background-color: #687077;
    border-color: #626a70; }
.btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 138, 145, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(129, 138, 145, 0.5); }
.btn-gray-400 {
  color: #151b1e;
  background-color: #869fac;
  border-color: #869fac; }
.btn-gray-400:hover {
    color: #fff;
    background-color: #6f8d9c;
    border-color: #688797; }
.btn-gray-400:focus, .btn-gray-400.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5); }
.btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #151b1e;
    background-color: #869fac;
    border-color: #869fac; }
.btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #fff;
    background-color: #688797;
    border-color: #638090; }
.btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5); }
.btn-gray-500 {
  color: #fff;
  background-color: #678898;
  border-color: #678898; }
.btn-gray-500:hover {
    color: #fff;
    background-color: #587481;
    border-color: #526d7a; }
.btn-gray-500:focus, .btn-gray-500.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5); }
.btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #fff;
    background-color: #678898;
    border-color: #678898; }
.btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #526d7a;
    border-color: #4d6672; }
.btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5); }
.btn-gray-600 {
  color: #151b1e;
  background-color: #9fa1a3;
  border-color: #9fa1a3; }
.btn-gray-600:hover {
    color: #fff;
    background-color: #8b8e90;
    border-color: #85888a; }
.btn-gray-600:focus, .btn-gray-600.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(159, 161, 163, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(159, 161, 163, 0.5); }
.btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #151b1e;
    background-color: #9fa1a3;
    border-color: #9fa1a3; }
.btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #85888a;
    border-color: #7e8184; }
.btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(159, 161, 163, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(159, 161, 163, 0.5); }
.btn-gray-700 {
  color: #fff;
  background-color: #3e515b;
  border-color: #3e515b; }
.btn-gray-700:hover {
    color: #fff;
    background-color: #2f3d44;
    border-color: #29363d; }
.btn-gray-700:focus, .btn-gray-700.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5); }
.btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #fff;
    background-color: #3e515b;
    border-color: #3e515b; }
.btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #29363d;
    border-color: #242f35; }
.btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5); }
.btn-gray-800 {
  color: #fff;
  background-color: #2a2c36;
  border-color: #2a2c36; }
.btn-gray-800:hover {
    color: #fff;
    background-color: #191a20;
    border-color: #141519; }
.btn-gray-800:focus, .btn-gray-800.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5); }
.btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #fff;
    background-color: #2a2c36;
    border-color: #2a2c36; }
.btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #141519;
    border-color: #0e0f12; }
.btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5); }
.btn-gray-900 {
  color: #fff;
  background-color: #151b1e;
  border-color: #151b1e; }
.btn-gray-900:hover {
    color: #fff;
    background-color: #050708;
    border-color: black; }
.btn-gray-900:focus, .btn-gray-900.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5); }
.btn-gray-900.disabled, .btn-gray-900:disabled {
    color: #fff;
    background-color: #151b1e;
    border-color: #151b1e; }
.btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-gray-900.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
.btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-900.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5); }
.btn-outline-primary {
  color: #f6174d;
  background-color: transparent;
  background-image: none;
  border-color: #f6174d; }
.btn-outline-primary:hover {
    color: #fff;
    background-color: #f6174d;
    border-color: #f6174d; }
.btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.5); }
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #f6174d;
    background-color: transparent; }
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #f6174d;
    border-color: #f6174d; }
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.5); }
.btn-outline-secondary {
  color: #30323d;
  background-color: transparent;
  background-image: none;
  border-color: #30323d; }
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #30323d;
    border-color: #30323d; }
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 50, 61, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(48, 50, 61, 0.5); }
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #30323d;
    background-color: transparent; }
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #30323d;
    border-color: #30323d; }
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 50, 61, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(48, 50, 61, 0.5); }
.btn-outline-success {
  color: #4dbd74;
  background-color: transparent;
  background-image: none;
  border-color: #4dbd74; }
.btn-outline-success:hover {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
.btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4dbd74;
    background-color: transparent; }
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
.btn-outline-info {
  color: #63c2de;
  background-color: transparent;
  background-image: none;
  border-color: #63c2de; }
.btn-outline-info:hover {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
.btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #63c2de;
    background-color: transparent; }
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
.btn-outline-warning {
  color: #f8cb00;
  background-color: transparent;
  background-image: none;
  border-color: #f8cb00; }
.btn-outline-warning:hover {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
.btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f8cb00;
    background-color: transparent; }
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
.btn-outline-danger {
  color: #f86c6b;
  background-color: transparent;
  background-image: none;
  border-color: #f86c6b; }
.btn-outline-danger:hover {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
.btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f86c6b;
    background-color: transparent; }
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
.btn-outline-light:hover {
    color: #151b1e;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
.btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #151b1e;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-outline-dark {
  color: #2a2c36;
  background-color: transparent;
  background-image: none;
  border-color: #2a2c36; }
.btn-outline-dark:hover {
    color: #fff;
    background-color: #2a2c36;
    border-color: #2a2c36; }
.btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5); }
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2a2c36;
    background-color: transparent; }
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #2a2c36;
    border-color: #2a2c36; }
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5); }
.btn-outline-blue {
  color: #20a8d8;
  background-color: transparent;
  background-image: none;
  border-color: #20a8d8; }
.btn-outline-blue:hover {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
.btn-outline-blue:focus, .btn-outline-blue.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }
.btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #20a8d8;
    background-color: transparent; }
.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }
.btn-outline-indigo {
  color: #6610f2;
  background-color: transparent;
  background-image: none;
  border-color: #6610f2; }
.btn-outline-indigo:hover {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
.btn-outline-indigo:focus, .btn-outline-indigo.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }
.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #6610f2;
    background-color: transparent; }
.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
.btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }
.btn-outline-purple {
  color: #6f42c1;
  background-color: transparent;
  background-image: none;
  border-color: #6f42c1; }
.btn-outline-purple:hover {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
.btn-outline-purple:focus, .btn-outline-purple.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }
.btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #6f42c1;
    background-color: transparent; }
.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }
.btn-outline-pink {
  color: #e83e8c;
  background-color: transparent;
  background-image: none;
  border-color: #e83e8c; }
.btn-outline-pink:hover {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
.btn-outline-pink:focus, .btn-outline-pink.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
.btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #e83e8c;
    background-color: transparent; }
.btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
.btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
.btn-outline-red {
  color: #f86c6b;
  background-color: transparent;
  background-image: none;
  border-color: #f86c6b; }
.btn-outline-red:hover {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
.btn-outline-red:focus, .btn-outline-red.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
.btn-outline-red.disabled, .btn-outline-red:disabled {
    color: #f86c6b;
    background-color: transparent; }
.btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-red.dropdown-toggle {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
.btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-red.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
.btn-outline-orange {
  color: #f8cb00;
  background-color: transparent;
  background-image: none;
  border-color: #f8cb00; }
.btn-outline-orange:hover {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
.btn-outline-orange:focus, .btn-outline-orange.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
.btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #f8cb00;
    background-color: transparent; }
.btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
.btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
.btn-outline-yellow {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
.btn-outline-yellow:hover {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
.btn-outline-yellow:focus, .btn-outline-yellow.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
.btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
    color: #ffc107;
    background-color: transparent; }
.btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow.dropdown-toggle {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
.btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
.btn-outline-green {
  color: #4dbd74;
  background-color: transparent;
  background-image: none;
  border-color: #4dbd74; }
.btn-outline-green:hover {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
.btn-outline-green:focus, .btn-outline-green.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
.btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #4dbd74;
    background-color: transparent; }
.btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
.btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
.btn-outline-teal {
  color: #20c997;
  background-color: transparent;
  background-image: none;
  border-color: #20c997; }
.btn-outline-teal:hover {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
.btn-outline-teal:focus, .btn-outline-teal.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }
.btn-outline-teal.disabled, .btn-outline-teal:disabled {
    color: #20c997;
    background-color: transparent; }
.btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
.btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-teal.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }
.btn-outline-cyan {
  color: #63c2de;
  background-color: transparent;
  background-image: none;
  border-color: #63c2de; }
.btn-outline-cyan:hover {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
.btn-outline-cyan:focus, .btn-outline-cyan.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
.btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
    color: #63c2de;
    background-color: transparent; }
.btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cyan.dropdown-toggle {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cyan.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
.btn-outline-gray-100 {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
.btn-outline-gray-100:hover {
    color: #151b1e;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
.btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
    color: #f8f9fa;
    background-color: transparent; }
.btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #151b1e;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
.btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-100.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-outline-gray-200 {
  color: #d1d4d7;
  background-color: transparent;
  background-image: none;
  border-color: #d1d4d7; }
.btn-outline-gray-200:hover {
    color: #151b1e;
    background-color: #d1d4d7;
    border-color: #d1d4d7; }
.btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 212, 215, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(209, 212, 215, 0.5); }
.btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #d1d4d7;
    background-color: transparent; }
.btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #151b1e;
    background-color: #d1d4d7;
    border-color: #d1d4d7; }
.btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-200.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 212, 215, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(209, 212, 215, 0.5); }
.btn-outline-gray-300 {
  color: #818a91;
  background-color: transparent;
  background-image: none;
  border-color: #818a91; }
.btn-outline-gray-300:hover {
    color: #fff;
    background-color: #818a91;
    border-color: #818a91; }
.btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 138, 145, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(129, 138, 145, 0.5); }
.btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
    color: #818a91;
    background-color: transparent; }
.btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-300.dropdown-toggle {
    color: #fff;
    background-color: #818a91;
    border-color: #818a91; }
.btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-300.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 138, 145, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(129, 138, 145, 0.5); }
.btn-outline-gray-400 {
  color: #869fac;
  background-color: transparent;
  background-image: none;
  border-color: #869fac; }
.btn-outline-gray-400:hover {
    color: #151b1e;
    background-color: #869fac;
    border-color: #869fac; }
.btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5); }
.btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #869fac;
    background-color: transparent; }
.btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #151b1e;
    background-color: #869fac;
    border-color: #869fac; }
.btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-400.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5); }
.btn-outline-gray-500 {
  color: #678898;
  background-color: transparent;
  background-image: none;
  border-color: #678898; }
.btn-outline-gray-500:hover {
    color: #fff;
    background-color: #678898;
    border-color: #678898; }
.btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5); }
.btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
    color: #678898;
    background-color: transparent; }
.btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #678898;
    border-color: #678898; }
.btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-500.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5); }
.btn-outline-gray-600 {
  color: #9fa1a3;
  background-color: transparent;
  background-image: none;
  border-color: #9fa1a3; }
.btn-outline-gray-600:hover {
    color: #151b1e;
    background-color: #9fa1a3;
    border-color: #9fa1a3; }
.btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(159, 161, 163, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(159, 161, 163, 0.5); }
.btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #9fa1a3;
    background-color: transparent; }
.btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #151b1e;
    background-color: #9fa1a3;
    border-color: #9fa1a3; }
.btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-600.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(159, 161, 163, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(159, 161, 163, 0.5); }
.btn-outline-gray-700 {
  color: #3e515b;
  background-color: transparent;
  background-image: none;
  border-color: #3e515b; }
.btn-outline-gray-700:hover {
    color: #fff;
    background-color: #3e515b;
    border-color: #3e515b; }
.btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5); }
.btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
    color: #3e515b;
    background-color: transparent; }
.btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #3e515b;
    border-color: #3e515b; }
.btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-700.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5); }
.btn-outline-gray-800 {
  color: #2a2c36;
  background-color: transparent;
  background-image: none;
  border-color: #2a2c36; }
.btn-outline-gray-800:hover {
    color: #fff;
    background-color: #2a2c36;
    border-color: #2a2c36; }
.btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5); }
.btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
    color: #2a2c36;
    background-color: transparent; }
.btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #2a2c36;
    border-color: #2a2c36; }
.btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-800.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(42, 44, 54, 0.5); }
.btn-outline-gray-900 {
  color: #151b1e;
  background-color: transparent;
  background-image: none;
  border-color: #151b1e; }
.btn-outline-gray-900:hover {
    color: #fff;
    background-color: #151b1e;
    border-color: #151b1e; }
.btn-outline-gray-900:focus, .btn-outline-gray-900.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5); }
.btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled {
    color: #151b1e;
    background-color: transparent; }
.btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #151b1e;
    border-color: #151b1e; }
.btn-outline-gray-900:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-900.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5); }
.btn-link {
  font-weight: 400;
  color: #f6174d;
  background-color: transparent; }
.btn-link:hover {
    color: #b90732;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
.btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none; }
.btn-link:disabled, .btn-link.disabled {
    color: #9fa1a3;
    pointer-events: none; }
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0; }
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0; }
.btn-block {
  display: block;
  width: 100%; }
.btn-block + .btn-block {
    margin-top: 0.5rem; }
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }
.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
@media screen and (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
.fade:not(.show) {
    opacity: 0; }
.collapse:not(.show) {
  display: none; }
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
@media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }
.dropdown-toggle:empty::after {
  margin-left: 0; }
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #151b1e;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d4d7; }
.dropdown-menu-right {
  right: 0;
  left: auto; }
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }
.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }
.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }
.dropright .dropdown-toggle::after {
  vertical-align: 0; }
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }
.dropleft .dropdown-toggle::after {
  display: none; }
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }
.dropleft .dropdown-toggle::before {
  vertical-align: 0; }
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f8f9fa; }
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #151b1e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
.dropdown-item:hover, .dropdown-item:focus {
    color: #0b0e0f;
    text-decoration: none;
    background-color: #f8f9fa; }
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #f6174d; }
.dropdown-item.disabled, .dropdown-item:disabled {
    color: #9fa1a3;
    background-color: transparent; }
.dropdown-menu.show {
  display: block; }
.dropdown-header {
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #9fa1a3;
  white-space: nowrap; }
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #151b1e; }
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
.btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
.btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
.btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }
.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
.btn-toolbar .input-group {
    width: auto; }
.btn-group > .btn:first-child {
  margin-left: 0; }
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
.dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
.dropleft .dropdown-toggle-split::before {
    margin-right: 0; }
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
.btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
.btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
.btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%; }
.input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
.input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
.input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
.input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
.input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
.input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
.input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }
.input-group-prepend {
  margin-right: -1px; }
.input-group-append {
  margin-left: -1px; }
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  text-align: center;
  white-space: nowrap;
  background-color: #f8f9fa;
  border: 1px solid #d1d4d7; }
.input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.64062rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5; }
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.64844rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }
.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }
.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #f6174d; }
.custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(246, 23, 77, 0.25);
            box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(246, 23, 77, 0.25); }
.custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fdc3d1; }
.custom-control-input:disabled ~ .custom-control-label {
    color: #9fa1a3; }
.custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #d1d4d7; }
.custom-control-label {
  position: relative;
  margin-bottom: 0; }
.custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #818a91; }
.custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f6174d; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #f6174d; }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(246, 23, 77, 0.5); }
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(246, 23, 77, 0.5); }
.custom-radio .custom-control-label::before {
  border-radius: 50%; }
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f6174d; }
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(246, 23, 77, 0.5); }
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #3e515b;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%232a2c36' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #d1d4d7;
  border-radius: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
.custom-select:focus {
    border-color: #fb92ab;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 146, 171, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(251, 146, 171, 0.5); }
.custom-select:focus::-ms-value {
      color: #3e515b;
      background-color: #fff; }
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
.custom-select:disabled {
    color: #9fa1a3;
    background-color: #d1d4d7; }
.custom-select::-ms-expand {
    opacity: 0; }
.custom-select-sm {
  height: calc(1.64844rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }
.custom-select-lg {
  height: calc(2.64062rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  margin-bottom: 0; }
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.0625rem + 2px);
  margin: 0;
  opacity: 0; }
.custom-file-input:focus ~ .custom-file-label {
    border-color: #fb92ab;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.25); }
.custom-file-input:focus ~ .custom-file-label::after {
      border-color: #fb92ab; }
.custom-file-input:disabled ~ .custom-file-label {
    background-color: #d1d4d7; }
.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #3e515b;
  background-color: #fff;
  border: 1px solid #d1d4d7; }
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.0625rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #3e515b;
    content: "Browse";
    background-color: #f8f9fa;
    border-left: 1px solid #d1d4d7; }
.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
.custom-range:focus {
    outline: none; }
.custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(246, 23, 77, 0.25);
              box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(246, 23, 77, 0.25); }
.custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(246, 23, 77, 0.25); }
.custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(246, 23, 77, 0.25); }
.custom-range::-moz-focus-outer {
    border: 0; }
.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #f6174d;
    border: 0;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
@media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
.custom-range::-webkit-slider-thumb:active {
      background-color: #fdc3d1; }
.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #818a91;
    border-color: transparent; }
.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #f6174d;
    border: 0;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
@media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none; } }
.custom-range::-moz-range-thumb:active {
      background-color: #fdc3d1; }
.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #818a91;
    border-color: transparent; }
.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #f6174d;
    border: 0;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: none; }
@media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none; } }
.custom-range::-ms-thumb:active {
      background-color: #fdc3d1; }
.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
.custom-range::-ms-fill-lower {
    background-color: #818a91; }
.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #818a91; }
.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
@media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }
.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
.nav-link:hover, .nav-link:focus {
    text-decoration: none; }
.nav-link.disabled {
    color: #9fa1a3; }
.nav-tabs {
  border-bottom: 1px solid #818a91; }
.nav-tabs .nav-item {
    margin-bottom: -1px; }
.nav-tabs .nav-link {
    border: 1px solid transparent; }
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #d1d4d7 #d1d4d7 #818a91; }
.nav-tabs .nav-link.disabled {
      color: #9fa1a3;
      background-color: transparent;
      border-color: transparent; }
.nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #3e515b;
    background-color: #e4e5e6;
    border-color: #818a91 #818a91 #e4e5e6; }
.nav-tabs .dropdown-menu {
    margin-top: -1px; }
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f6174d; }
.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center; }
.tab-content > .tab-pane {
  display: none; }
.tab-content > .active {
  display: block; }
.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
.navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }
.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
.navbar-nav .dropdown-menu {
    position: static;
    float: none; }
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }
.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
.navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
.navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
.navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
.navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
.navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
.navbar-expand .navbar-toggler {
    display: none; }
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }
.navbar-dark .navbar-brand {
  color: #fff; }
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
.navbar-dark .navbar-text a {
    color: #fff; }
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d1d4d7; }
.card > hr {
    margin-right: 0;
    margin-left: 0; }
.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.25rem; }
.card-title {
  margin-bottom: 0.75rem; }
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }
.card-text:last-child {
  margin-bottom: 0; }
.card-link:hover {
  text-decoration: none; }
.card-link + .card-link {
  margin-left: 1.25rem; }
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f8f9fa;
  border-bottom: 1px solid #d1d4d7; }
.card-header + .list-group .list-group-item:first-child {
    border-top: 0; }
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fa;
  border-top: 1px solid #d1d4d7; }
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }
.card-img {
  width: 100%; }
.card-img-top {
  width: 100%; }
.card-img-bottom {
  width: 100%; }
.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
.card-deck .card {
    margin-bottom: 15px; }
@media (min-width: 576px) {
    .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
                flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }
.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
.card-group > .card {
    margin-bottom: 15px; }
@media (min-width: 576px) {
    .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; }
      .card-group > .card {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
                flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }
.card-columns .card {
  margin-bottom: 0.75rem; }
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  list-style: none;
  background-color: #fff; }
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #9fa1a3;
    content: "/"; }
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }
.breadcrumb-item.active {
  color: #9fa1a3; }
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none; }
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #f6174d;
  background-color: #fff;
  border: 1px solid #818a91; }
.page-link:hover {
    z-index: 2;
    color: #b90732;
    text-decoration: none;
    background-color: #d1d4d7;
    border-color: #818a91; }
.page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(246, 23, 77, 0.25); }
.page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }
.page-item:first-child .page-link {
  margin-left: 0; }
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #f6174d;
  border-color: #f6174d; }
.page-item.disabled .page-link {
  color: #9fa1a3;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #818a91; }
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
.badge:empty {
    display: none; }
.btn .badge {
  position: relative;
  top: -1px; }
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }
.badge-primary {
  color: #fff;
  background-color: #f6174d; }
.badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #d20839; }
.badge-secondary {
  color: #fff;
  background-color: #30323d; }
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1a1b20; }
.badge-success {
  color: #fff;
  background-color: #4dbd74; }
.badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3a9d5d; }
.badge-info {
  color: #151b1e;
  background-color: #63c2de; }
.badge-info[href]:hover, .badge-info[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #39b2d5; }
.badge-warning {
  color: #151b1e;
  background-color: #f8cb00; }
.badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #c5a100; }
.badge-danger {
  color: #fff;
  background-color: #f86c6b; }
.badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #f63c3a; }
.badge-light {
  color: #151b1e;
  background-color: #f8f9fa; }
.badge-light[href]:hover, .badge-light[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #dae0e5; }
.badge-dark {
  color: #fff;
  background-color: #2a2c36; }
.badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #141519; }
.badge-blue {
  color: #fff;
  background-color: #20a8d8; }
.badge-blue[href]:hover, .badge-blue[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1985ac; }
.badge-indigo {
  color: #fff;
  background-color: #6610f2; }
.badge-indigo[href]:hover, .badge-indigo[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #510bc4; }
.badge-purple {
  color: #fff;
  background-color: #6f42c1; }
.badge-purple[href]:hover, .badge-purple[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #59339d; }
.badge-pink {
  color: #fff;
  background-color: #e83e8c; }
.badge-pink[href]:hover, .badge-pink[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #d91a72; }
.badge-red {
  color: #fff;
  background-color: #f86c6b; }
.badge-red[href]:hover, .badge-red[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #f63c3a; }
.badge-orange {
  color: #151b1e;
  background-color: #f8cb00; }
.badge-orange[href]:hover, .badge-orange[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #c5a100; }
.badge-yellow {
  color: #151b1e;
  background-color: #ffc107; }
.badge-yellow[href]:hover, .badge-yellow[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #d39e00; }
.badge-green {
  color: #fff;
  background-color: #4dbd74; }
.badge-green[href]:hover, .badge-green[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3a9d5d; }
.badge-teal {
  color: #fff;
  background-color: #20c997; }
.badge-teal[href]:hover, .badge-teal[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #199d76; }
.badge-cyan {
  color: #151b1e;
  background-color: #63c2de; }
.badge-cyan[href]:hover, .badge-cyan[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #39b2d5; }
.badge-gray-100 {
  color: #151b1e;
  background-color: #f8f9fa; }
.badge-gray-100[href]:hover, .badge-gray-100[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #dae0e5; }
.badge-gray-200 {
  color: #151b1e;
  background-color: #d1d4d7; }
.badge-gray-200[href]:hover, .badge-gray-200[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #b6bbbf; }
.badge-gray-300 {
  color: #fff;
  background-color: #818a91; }
.badge-gray-300[href]:hover, .badge-gray-300[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #687077; }
.badge-gray-400 {
  color: #151b1e;
  background-color: #869fac; }
.badge-gray-400[href]:hover, .badge-gray-400[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #688797; }
.badge-gray-500 {
  color: #fff;
  background-color: #678898; }
.badge-gray-500[href]:hover, .badge-gray-500[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #526d7a; }
.badge-gray-600 {
  color: #151b1e;
  background-color: #9fa1a3; }
.badge-gray-600[href]:hover, .badge-gray-600[href]:focus {
    color: #151b1e;
    text-decoration: none;
    background-color: #85888a; }
.badge-gray-700 {
  color: #fff;
  background-color: #3e515b; }
.badge-gray-700[href]:hover, .badge-gray-700[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #29363d; }
.badge-gray-800 {
  color: #fff;
  background-color: #2a2c36; }
.badge-gray-800[href]:hover, .badge-gray-800[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #141519; }
.badge-gray-900 {
  color: #fff;
  background-color: #151b1e; }
.badge-gray-900[href]:hover, .badge-gray-900[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: black; }
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #d1d4d7; }
@media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }
.alert-heading {
  color: inherit; }
.alert-link {
  font-weight: 700; }
.alert-dismissible {
  padding-right: 3.8125rem; }
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }
.alert-primary {
  color: #800c28;
  background-color: #fdd1db;
  border-color: #fcbecd; }
.alert-primary hr {
    border-top-color: #fba6ba; }
.alert-primary .alert-link {
    color: #510819; }
.alert-secondary {
  color: #191a20;
  background-color: #d6d6d8;
  border-color: #c5c6c9; }
.alert-secondary hr {
    border-top-color: #b8b9bd; }
.alert-secondary .alert-link {
    color: #030303; }
.alert-success {
  color: #28623c;
  background-color: #dbf2e3;
  border-color: #cdedd8; }
.alert-success hr {
    border-top-color: #bae6c9; }
.alert-success .alert-link {
    color: #193e26; }
.alert-info {
  color: #336573;
  background-color: #e0f3f8;
  border-color: #d3eef6; }
.alert-info hr {
    border-top-color: #bee6f2; }
.alert-info .alert-link {
    color: #234650; }
.alert-warning {
  color: #816a00;
  background-color: #fef5cc;
  border-color: #fdf0b8; }
.alert-warning hr {
    border-top-color: #fceb9f; }
.alert-warning .alert-link {
    color: #4e4000; }
.alert-danger {
  color: #813838;
  background-color: #fee2e1;
  border-color: #fdd6d6; }
.alert-danger hr {
    border-top-color: #fcbebe; }
.alert-danger .alert-link {
    color: #5d2929; }
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
.alert-light hr {
    border-top-color: #ececf6; }
.alert-light .alert-link {
    color: #686868; }
.alert-dark {
  color: #16171c;
  background-color: #d4d5d7;
  border-color: #c3c4c7; }
.alert-dark hr {
    border-top-color: #b6b7bb; }
.alert-dark .alert-link {
    color: black; }
.alert-blue {
  color: #115770;
  background-color: #d2eef7;
  border-color: #c1e7f4; }
.alert-blue hr {
    border-top-color: #abdff0; }
.alert-blue .alert-link {
    color: #0a3544; }
.alert-indigo {
  color: #35087e;
  background-color: #e0cffc;
  border-color: #d4bcfb; }
.alert-indigo hr {
    border-top-color: #c5a4fa; }
.alert-indigo .alert-link {
    color: #21054e; }
.alert-purple {
  color: #3a2264;
  background-color: #e2d9f3;
  border-color: #d7caee; }
.alert-purple hr {
    border-top-color: #c8b7e8; }
.alert-purple .alert-link {
    color: #24153e; }
.alert-pink {
  color: #792049;
  background-color: #fad8e8;
  border-color: #f9c9df; }
.alert-pink hr {
    border-top-color: #f6b2d1; }
.alert-pink .alert-link {
    color: #511531; }
.alert-red {
  color: #813838;
  background-color: #fee2e1;
  border-color: #fdd6d6; }
.alert-red hr {
    border-top-color: #fcbebe; }
.alert-red .alert-link {
    color: #5d2929; }
.alert-orange {
  color: #816a00;
  background-color: #fef5cc;
  border-color: #fdf0b8; }
.alert-orange hr {
    border-top-color: #fceb9f; }
.alert-orange .alert-link {
    color: #4e4000; }
.alert-yellow {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
.alert-yellow hr {
    border-top-color: #ffe8a1; }
.alert-yellow .alert-link {
    color: #533f03; }
.alert-green {
  color: #28623c;
  background-color: #dbf2e3;
  border-color: #cdedd8; }
.alert-green hr {
    border-top-color: #bae6c9; }
.alert-green .alert-link {
    color: #193e26; }
.alert-teal {
  color: #11694f;
  background-color: #d2f4ea;
  border-color: #c1f0e2; }
.alert-teal hr {
    border-top-color: #acebd8; }
.alert-teal .alert-link {
    color: #0a3d2e; }
.alert-cyan {
  color: #336573;
  background-color: #e0f3f8;
  border-color: #d3eef6; }
.alert-cyan hr {
    border-top-color: #bee6f2; }
.alert-cyan .alert-link {
    color: #234650; }
.alert-gray-100 {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
.alert-gray-100 hr {
    border-top-color: #ececf6; }
.alert-gray-100 .alert-link {
    color: #686868; }
.alert-gray-200 {
  color: #6d6e70;
  background-color: #f6f6f7;
  border-color: #f2f3f4; }
.alert-gray-200 hr {
    border-top-color: #e4e6e8; }
.alert-gray-200 .alert-link {
    color: #545556; }
.alert-gray-300 {
  color: #43484b;
  background-color: #e6e8e9;
  border-color: #dcdee0; }
.alert-gray-300 hr {
    border-top-color: #ced1d4; }
.alert-gray-300 .alert-link {
    color: #2b2e30; }
.alert-gray-400 {
  color: #465359;
  background-color: #e7ecee;
  border-color: #dde4e8; }
.alert-gray-400 hr {
    border-top-color: #ced8de; }
.alert-gray-400 .alert-link {
    color: #30383c; }
.alert-gray-500 {
  color: #36474f;
  background-color: #e1e7ea;
  border-color: #d4dee2; }
.alert-gray-500 hr {
    border-top-color: #c5d2d8; }
.alert-gray-500 .alert-link {
    color: #212c31; }
.alert-gray-600 {
  color: #535455;
  background-color: #ececed;
  border-color: #e4e5e5; }
.alert-gray-600 hr {
    border-top-color: #d7d8d8; }
.alert-gray-600 .alert-link {
    color: #3a3b3b; }
.alert-gray-700 {
  color: #202a2f;
  background-color: #d8dcde;
  border-color: #c9ced1; }
.alert-gray-700 hr {
    border-top-color: #bbc2c5; }
.alert-gray-700 .alert-link {
    color: #0b0f11; }
.alert-gray-800 {
  color: #16171c;
  background-color: #d4d5d7;
  border-color: #c3c4c7; }
.alert-gray-800 hr {
    border-top-color: #b6b7bb; }
.alert-gray-800 .alert-link {
    color: black; }
.alert-gray-900 {
  color: #0b0e10;
  background-color: #d0d1d2;
  border-color: #bdbfc0; }
.alert-gray-900 hr {
    border-top-color: #b0b2b4; }
.alert-gray-900 .alert-link {
    color: black; }
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #f8f9fa; }
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #f6174d;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
@media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
.media-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }
.list-group-item-action {
  width: 100%;
  color: #3e515b;
  text-align: inherit; }
.list-group-item-action:hover, .list-group-item-action:focus {
    color: #3e515b;
    text-decoration: none;
    background-color: #f8f9fa; }
.list-group-item-action:active {
    color: #151b1e;
    background-color: #d1d4d7; }
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
.list-group-item:last-child {
    margin-bottom: 0; }
.list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
.list-group-item.disabled, .list-group-item:disabled {
    color: #9fa1a3;
    background-color: #fff; }
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #f6174d;
    border-color: #f6174d; }
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }
.list-group-item-primary {
  color: #800c28;
  background-color: #fcbecd; }
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #800c28;
    background-color: #fba6ba; }
.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #800c28;
    border-color: #800c28; }
.list-group-item-secondary {
  color: #191a20;
  background-color: #c5c6c9; }
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #191a20;
    background-color: #b8b9bd; }
.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #191a20;
    border-color: #191a20; }
.list-group-item-success {
  color: #28623c;
  background-color: #cdedd8; }
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #28623c;
    background-color: #bae6c9; }
.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #28623c;
    border-color: #28623c; }
.list-group-item-info {
  color: #336573;
  background-color: #d3eef6; }
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #336573;
    background-color: #bee6f2; }
.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #336573;
    border-color: #336573; }
.list-group-item-warning {
  color: #816a00;
  background-color: #fdf0b8; }
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #816a00;
    background-color: #fceb9f; }
.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #816a00;
    border-color: #816a00; }
.list-group-item-danger {
  color: #813838;
  background-color: #fdd6d6; }
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #813838;
    background-color: #fcbebe; }
.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #813838;
    border-color: #813838; }
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }
.list-group-item-dark {
  color: #16171c;
  background-color: #c3c4c7; }
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #16171c;
    background-color: #b6b7bb; }
.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #16171c;
    border-color: #16171c; }
.list-group-item-blue {
  color: #115770;
  background-color: #c1e7f4; }
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
    color: #115770;
    background-color: #abdff0; }
.list-group-item-blue.list-group-item-action.active {
    color: #fff;
    background-color: #115770;
    border-color: #115770; }
.list-group-item-indigo {
  color: #35087e;
  background-color: #d4bcfb; }
.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
    color: #35087e;
    background-color: #c5a4fa; }
.list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #35087e;
    border-color: #35087e; }
.list-group-item-purple {
  color: #3a2264;
  background-color: #d7caee; }
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #3a2264;
    background-color: #c8b7e8; }
.list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #3a2264;
    border-color: #3a2264; }
.list-group-item-pink {
  color: #792049;
  background-color: #f9c9df; }
.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #792049;
    background-color: #f6b2d1; }
.list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #792049;
    border-color: #792049; }
.list-group-item-red {
  color: #813838;
  background-color: #fdd6d6; }
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
    color: #813838;
    background-color: #fcbebe; }
.list-group-item-red.list-group-item-action.active {
    color: #fff;
    background-color: #813838;
    border-color: #813838; }
.list-group-item-orange {
  color: #816a00;
  background-color: #fdf0b8; }
.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #816a00;
    background-color: #fceb9f; }
.list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #816a00;
    border-color: #816a00; }
.list-group-item-yellow {
  color: #856404;
  background-color: #ffeeba; }
.list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
.list-group-item-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }
.list-group-item-green {
  color: #28623c;
  background-color: #cdedd8; }
.list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
    color: #28623c;
    background-color: #bae6c9; }
.list-group-item-green.list-group-item-action.active {
    color: #fff;
    background-color: #28623c;
    border-color: #28623c; }
.list-group-item-teal {
  color: #11694f;
  background-color: #c1f0e2; }
.list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
    color: #11694f;
    background-color: #acebd8; }
.list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #11694f;
    border-color: #11694f; }
.list-group-item-cyan {
  color: #336573;
  background-color: #d3eef6; }
.list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
    color: #336573;
    background-color: #bee6f2; }
.list-group-item-cyan.list-group-item-action.active {
    color: #fff;
    background-color: #336573;
    border-color: #336573; }
.list-group-item-gray-100 {
  color: #818182;
  background-color: #fdfdfe; }
.list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
.list-group-item-gray-100.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }
.list-group-item-gray-200 {
  color: #6d6e70;
  background-color: #f2f3f4; }
.list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
    color: #6d6e70;
    background-color: #e4e6e8; }
.list-group-item-gray-200.list-group-item-action.active {
    color: #fff;
    background-color: #6d6e70;
    border-color: #6d6e70; }
.list-group-item-gray-300 {
  color: #43484b;
  background-color: #dcdee0; }
.list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
    color: #43484b;
    background-color: #ced1d4; }
.list-group-item-gray-300.list-group-item-action.active {
    color: #fff;
    background-color: #43484b;
    border-color: #43484b; }
.list-group-item-gray-400 {
  color: #465359;
  background-color: #dde4e8; }
.list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
    color: #465359;
    background-color: #ced8de; }
.list-group-item-gray-400.list-group-item-action.active {
    color: #fff;
    background-color: #465359;
    border-color: #465359; }
.list-group-item-gray-500 {
  color: #36474f;
  background-color: #d4dee2; }
.list-group-item-gray-500.list-group-item-action:hover, .list-group-item-gray-500.list-group-item-action:focus {
    color: #36474f;
    background-color: #c5d2d8; }
.list-group-item-gray-500.list-group-item-action.active {
    color: #fff;
    background-color: #36474f;
    border-color: #36474f; }
.list-group-item-gray-600 {
  color: #535455;
  background-color: #e4e5e5; }
.list-group-item-gray-600.list-group-item-action:hover, .list-group-item-gray-600.list-group-item-action:focus {
    color: #535455;
    background-color: #d7d8d8; }
.list-group-item-gray-600.list-group-item-action.active {
    color: #fff;
    background-color: #535455;
    border-color: #535455; }
.list-group-item-gray-700 {
  color: #202a2f;
  background-color: #c9ced1; }
.list-group-item-gray-700.list-group-item-action:hover, .list-group-item-gray-700.list-group-item-action:focus {
    color: #202a2f;
    background-color: #bbc2c5; }
.list-group-item-gray-700.list-group-item-action.active {
    color: #fff;
    background-color: #202a2f;
    border-color: #202a2f; }
.list-group-item-gray-800 {
  color: #16171c;
  background-color: #c3c4c7; }
.list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
    color: #16171c;
    background-color: #b6b7bb; }
.list-group-item-gray-800.list-group-item-action.active {
    color: #fff;
    background-color: #16171c;
    border-color: #16171c; }
.list-group-item-gray-900 {
  color: #0b0e10;
  background-color: #bdbfc0; }
.list-group-item-gray-900.list-group-item-action:hover, .list-group-item-gray-900.list-group-item-action:focus {
    color: #0b0e10;
    background-color: #b0b2b4; }
.list-group-item-gray-900.list-group-item-action.active {
    color: #fff;
    background-color: #0b0e10;
    border-color: #0b0e10; }
.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
.close:not(:disabled):not(.disabled) {
    cursor: pointer; }
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000;
      text-decoration: none;
      opacity: .75; }
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }
.modal-open {
  overflow: hidden; }
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
@media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0; }
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
.modal-backdrop.fade {
    opacity: 0; }
.modal-backdrop.show {
    opacity: 0.5; }
.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #d1d4d7; }
.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }
.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }
.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #d1d4d7; }
.modal-footer > :not(:first-child) {
    margin-left: .25rem; }
.modal-footer > :not(:last-child) {
    margin-right: .25rem; }
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0; }
.tooltip.show {
    opacity: 0.9; }
.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
.tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000; }
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2); }
.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
.popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }
.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
.bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }
.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
.bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
.bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
.bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
.popover-header:empty {
    display: none; }
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #151b1e; }
.carousel {
  position: relative; }
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease; }
@media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      -webkit-transition: none;
      transition: none; } }
.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }
.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-duration: .6s;
          transition-duration: .6s;
  -webkit-transition-property: opacity;
  transition-property: opacity; }
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
.carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }
.carousel-control-prev {
  left: 0; }
.carousel-control-next {
  right: 0; }
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
.carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
.carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
.carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
.carousel-indicators .active {
    background-color: #fff; }
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }
.align-baseline {
  vertical-align: baseline !important; }
.align-top {
  vertical-align: top !important; }
.align-middle {
  vertical-align: middle !important; }
.align-bottom {
  vertical-align: bottom !important; }
.align-text-bottom {
  vertical-align: text-bottom !important; }
.align-text-top {
  vertical-align: text-top !important; }
.bg-primary {
  background-color: #f6174d !important; }
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #d20839 !important; }
.bg-secondary {
  background-color: #30323d !important; }
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1a1b20 !important; }
.bg-success {
  background-color: #4dbd74 !important; }
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3a9d5d !important; }
.bg-info {
  background-color: #63c2de !important; }
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #39b2d5 !important; }
.bg-warning {
  background-color: #f8cb00 !important; }
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c5a100 !important; }
.bg-danger {
  background-color: #f86c6b !important; }
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f63c3a !important; }
.bg-light {
  background-color: #f8f9fa !important; }
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }
.bg-dark {
  background-color: #2a2c36 !important; }
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #141519 !important; }
.bg-blue {
  background-color: #20a8d8 !important; }
a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #1985ac !important; }
.bg-indigo {
  background-color: #6610f2 !important; }
a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important; }
.bg-purple {
  background-color: #6f42c1 !important; }
a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important; }
.bg-pink {
  background-color: #e83e8c !important; }
a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important; }
.bg-red {
  background-color: #f86c6b !important; }
a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #f63c3a !important; }
.bg-orange {
  background-color: #f8cb00 !important; }
a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #c5a100 !important; }
.bg-yellow {
  background-color: #ffc107 !important; }
a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important; }
.bg-green {
  background-color: #4dbd74 !important; }
a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #3a9d5d !important; }
.bg-teal {
  background-color: #20c997 !important; }
a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important; }
.bg-cyan {
  background-color: #63c2de !important; }
a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #39b2d5 !important; }
.bg-gray-100 {
  background-color: #f8f9fa !important; }
a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dae0e5 !important; }
.bg-gray-200 {
  background-color: #d1d4d7 !important; }
a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #b6bbbf !important; }
.bg-gray-300 {
  background-color: #818a91 !important; }
a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #687077 !important; }
.bg-gray-400 {
  background-color: #869fac !important; }
a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #688797 !important; }
.bg-gray-500 {
  background-color: #678898 !important; }
a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #526d7a !important; }
.bg-gray-600 {
  background-color: #9fa1a3 !important; }
a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #85888a !important; }
.bg-gray-700 {
  background-color: #3e515b !important; }
a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #29363d !important; }
.bg-gray-800 {
  background-color: #2a2c36 !important; }
a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #141519 !important; }
.bg-gray-900 {
  background-color: #151b1e !important; }
a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: black !important; }
.bg-white {
  background-color: #fff !important; }
.bg-transparent {
  background-color: transparent !important; }
.border {
  border: 1px solid #818a91 !important; }
.border-top {
  border-top: 1px solid #818a91 !important; }
.border-right {
  border-right: 1px solid #818a91 !important; }
.border-bottom {
  border-bottom: 1px solid #818a91 !important; }
.border-left {
  border-left: 1px solid #818a91 !important; }
.border-0 {
  border: 0 !important; }
.border-top-0 {
  border-top: 0 !important; }
.border-right-0 {
  border-right: 0 !important; }
.border-bottom-0 {
  border-bottom: 0 !important; }
.border-left-0 {
  border-left: 0 !important; }
.border-primary {
  border-color: #f6174d !important; }
.border-secondary {
  border-color: #30323d !important; }
.border-success {
  border-color: #4dbd74 !important; }
.border-info {
  border-color: #63c2de !important; }
.border-warning {
  border-color: #f8cb00 !important; }
.border-danger {
  border-color: #f86c6b !important; }
.border-light {
  border-color: #f8f9fa !important; }
.border-dark {
  border-color: #2a2c36 !important; }
.border-blue {
  border-color: #20a8d8 !important; }
.border-indigo {
  border-color: #6610f2 !important; }
.border-purple {
  border-color: #6f42c1 !important; }
.border-pink {
  border-color: #e83e8c !important; }
.border-red {
  border-color: #f86c6b !important; }
.border-orange {
  border-color: #f8cb00 !important; }
.border-yellow {
  border-color: #ffc107 !important; }
.border-green {
  border-color: #4dbd74 !important; }
.border-teal {
  border-color: #20c997 !important; }
.border-cyan {
  border-color: #63c2de !important; }
.border-gray-100 {
  border-color: #f8f9fa !important; }
.border-gray-200 {
  border-color: #d1d4d7 !important; }
.border-gray-300 {
  border-color: #818a91 !important; }
.border-gray-400 {
  border-color: #869fac !important; }
.border-gray-500 {
  border-color: #678898 !important; }
.border-gray-600 {
  border-color: #9fa1a3 !important; }
.border-gray-700 {
  border-color: #3e515b !important; }
.border-gray-800 {
  border-color: #2a2c36 !important; }
.border-gray-900 {
  border-color: #151b1e !important; }
.border-white {
  border-color: #fff !important; }
.rounded {
  border-radius: 0.25rem !important; }
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }
.rounded-circle {
  border-radius: 50% !important; }
.rounded-0 {
  border-radius: 0 !important; }
.clearfix::after {
  display: block;
  clear: both;
  content: ""; }
.d-none {
  display: none !important; }
.d-inline {
  display: inline !important; }
.d-inline-block {
  display: inline-block !important; }
.d-block {
  display: block !important; }
.d-table {
  display: table !important; }
.d-table-row {
  display: table-row !important; }
.d-table-cell {
  display: table-cell !important; }
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }
.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }
@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }
@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
.embed-responsive::before {
    display: block;
    content: ""; }
.embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
.embed-responsive-21by9::before {
  padding-top: 42.85714%; }
.embed-responsive-16by9::before {
  padding-top: 56.25%; }
.embed-responsive-4by3::before {
  padding-top: 75%; }
.embed-responsive-1by1::before {
  padding-top: 100%; }
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }
.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }
.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }
.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }
.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }
.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }
.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }
.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }
.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }
.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }
.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }
.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }
.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }
.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }
.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }
.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }
.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }
.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }
@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }
.float-left {
  float: left !important; }
.float-right {
  float: right !important; }
.float-none {
  float: none !important; }
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }
@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }
.position-static {
  position: static !important; }
.position-relative {
  position: relative !important; }
.position-absolute {
  position: absolute !important; }
.position-fixed {
  position: fixed !important; }
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }
.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }
.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }
.w-25 {
  width: 25% !important; }
.w-50 {
  width: 50% !important; }
.w-75 {
  width: 75% !important; }
.w-100 {
  width: 100% !important; }
.w-auto {
  width: auto !important; }
.h-25 {
  height: 25% !important; }
.h-50 {
  height: 50% !important; }
.h-75 {
  height: 75% !important; }
.h-100 {
  height: 100% !important; }
.h-auto {
  height: auto !important; }
.mw-100 {
  max-width: 100% !important; }
.mh-100 {
  max-height: 100% !important; }
.m-0 {
  margin: 0 !important; }
.mt-0,
.my-0 {
  margin-top: 0 !important; }
.mr-0,
.mx-0 {
  margin-right: 0 !important; }
.mb-0,
.my-0 {
  margin-bottom: 0 !important; }
.ml-0,
.mx-0 {
  margin-left: 0 !important; }
.m-1 {
  margin: 0.25rem !important; }
.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }
.m-2 {
  margin: 0.5rem !important; }
.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }
.m-3 {
  margin: 1rem !important; }
.mt-3,
.my-3 {
  margin-top: 1rem !important; }
.mr-3,
.mx-3 {
  margin-right: 1rem !important; }
.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }
.ml-3,
.mx-3 {
  margin-left: 1rem !important; }
.m-4 {
  margin: 1.5rem !important; }
.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }
.m-5 {
  margin: 3rem !important; }
.mt-5,
.my-5 {
  margin-top: 3rem !important; }
.mr-5,
.mx-5 {
  margin-right: 3rem !important; }
.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }
.ml-5,
.mx-5 {
  margin-left: 3rem !important; }
.p-0 {
  padding: 0 !important; }
.pt-0,
.py-0 {
  padding-top: 0 !important; }
.pr-0,
.px-0 {
  padding-right: 0 !important; }
.pb-0,
.py-0 {
  padding-bottom: 0 !important; }
.pl-0,
.px-0 {
  padding-left: 0 !important; }
.p-1 {
  padding: 0.25rem !important; }
.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }
.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }
.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }
.p-2 {
  padding: 0.5rem !important; }
.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }
.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }
.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }
.p-3 {
  padding: 1rem !important; }
.pt-3,
.py-3 {
  padding-top: 1rem !important; }
.pr-3,
.px-3 {
  padding-right: 1rem !important; }
.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }
.pl-3,
.px-3 {
  padding-left: 1rem !important; }
.p-4 {
  padding: 1.5rem !important; }
.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }
.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }
.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }
.p-5 {
  padding: 3rem !important; }
.pt-5,
.py-5 {
  padding-top: 3rem !important; }
.pr-5,
.px-5 {
  padding-right: 3rem !important; }
.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }
.pl-5,
.px-5 {
  padding-left: 3rem !important; }
.m-auto {
  margin: auto !important; }
.mt-auto,
.my-auto {
  margin-top: auto !important; }
.mr-auto,
.mx-auto {
  margin-right: auto !important; }
.mb-auto,
.my-auto {
  margin-bottom: auto !important; }
.ml-auto,
.mx-auto {
  margin-left: auto !important; }
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
.text-justify {
  text-align: justify !important; }
.text-nowrap {
  white-space: nowrap !important; }
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
.text-left {
  text-align: left !important; }
.text-right {
  text-align: right !important; }
.text-center {
  text-align: center !important; }
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }
.text-lowercase {
  text-transform: lowercase !important; }
.text-uppercase {
  text-transform: uppercase !important; }
.text-capitalize {
  text-transform: capitalize !important; }
.font-weight-light {
  font-weight: 300 !important; }
.font-weight-normal {
  font-weight: 400 !important; }
.font-weight-bold {
  font-weight: 700 !important; }
.font-italic {
  font-style: italic !important; }
.text-white {
  color: #fff !important; }
.text-primary {
  color: #f6174d !important; }
a.text-primary:hover, a.text-primary:focus {
  color: #d20839 !important; }
.text-secondary {
  color: #30323d !important; }
a.text-secondary:hover, a.text-secondary:focus {
  color: #1a1b20 !important; }
.text-success {
  color: #4dbd74 !important; }
a.text-success:hover, a.text-success:focus {
  color: #3a9d5d !important; }
.text-info {
  color: #63c2de !important; }
a.text-info:hover, a.text-info:focus {
  color: #39b2d5 !important; }
.text-warning {
  color: #f8cb00 !important; }
a.text-warning:hover, a.text-warning:focus {
  color: #c5a100 !important; }
.text-danger {
  color: #f86c6b !important; }
a.text-danger:hover, a.text-danger:focus {
  color: #f63c3a !important; }
.text-light {
  color: #f8f9fa !important; }
a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }
.text-dark {
  color: #2a2c36 !important; }
a.text-dark:hover, a.text-dark:focus {
  color: #141519 !important; }
.text-blue {
  color: #20a8d8 !important; }
a.text-blue:hover, a.text-blue:focus {
  color: #1985ac !important; }
.text-indigo {
  color: #6610f2 !important; }
a.text-indigo:hover, a.text-indigo:focus {
  color: #510bc4 !important; }
.text-purple {
  color: #6f42c1 !important; }
a.text-purple:hover, a.text-purple:focus {
  color: #59339d !important; }
.text-pink {
  color: #e83e8c !important; }
a.text-pink:hover, a.text-pink:focus {
  color: #d91a72 !important; }
.text-red {
  color: #f86c6b !important; }
a.text-red:hover, a.text-red:focus {
  color: #f63c3a !important; }
.text-orange {
  color: #f8cb00 !important; }
a.text-orange:hover, a.text-orange:focus {
  color: #c5a100 !important; }
.text-yellow {
  color: #ffc107 !important; }
a.text-yellow:hover, a.text-yellow:focus {
  color: #d39e00 !important; }
.text-green {
  color: #4dbd74 !important; }
a.text-green:hover, a.text-green:focus {
  color: #3a9d5d !important; }
.text-teal {
  color: #20c997 !important; }
a.text-teal:hover, a.text-teal:focus {
  color: #199d76 !important; }
.text-cyan {
  color: #63c2de !important; }
a.text-cyan:hover, a.text-cyan:focus {
  color: #39b2d5 !important; }
.text-gray-100 {
  color: #f8f9fa !important; }
a.text-gray-100:hover, a.text-gray-100:focus {
  color: #dae0e5 !important; }
.text-gray-200 {
  color: #d1d4d7 !important; }
a.text-gray-200:hover, a.text-gray-200:focus {
  color: #b6bbbf !important; }
.text-gray-300 {
  color: #818a91 !important; }
a.text-gray-300:hover, a.text-gray-300:focus {
  color: #687077 !important; }
.text-gray-400 {
  color: #869fac !important; }
a.text-gray-400:hover, a.text-gray-400:focus {
  color: #688797 !important; }
.text-gray-500 {
  color: #678898 !important; }
a.text-gray-500:hover, a.text-gray-500:focus {
  color: #526d7a !important; }
.text-gray-600 {
  color: #9fa1a3 !important; }
a.text-gray-600:hover, a.text-gray-600:focus {
  color: #85888a !important; }
.text-gray-700 {
  color: #3e515b !important; }
a.text-gray-700:hover, a.text-gray-700:focus {
  color: #29363d !important; }
.text-gray-800 {
  color: #2a2c36 !important; }
a.text-gray-800:hover, a.text-gray-800:focus {
  color: #141519 !important; }
.text-gray-900 {
  color: #151b1e !important; }
a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important; }
.text-body {
  color: #151b1e !important; }
.text-muted {
  color: #9fa1a3 !important; }
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }
.visible {
  visibility: visible !important; }
.invisible {
  visibility: hidden !important; }
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #678898;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #818a91 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #818a91; }
  .table .thead-dark th {
    color: inherit;
    border-color: #818a91; } }
/* FONTS */
@font-face {
  font-family: "MeiryoBold";
  src: url(/static/fonts/Meiryo-Bold.79a2138.woff2) format("woff2"), url(/static/fonts/Meiryo-Bold.d935f9a.woff) format("woff");
  font-weight: bold;
  font-style: normal; }
@font-face {
  font-family: "Meiryo";
  src: url(/static/fonts/Meiryo.30c28dd.woff2) format("woff2"), url(/static/fonts/Meiryo.1cf8846.woff) format("woff");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: "icomoon";
  src: url(/static/fonts/icomoon.b5d2779.eot);
  src: url(/static/fonts/icomoon.b5d2779.eot#iefix) format("embedded-opentype"), url(/static/fonts/icomoon.330a666.ttf) format("truetype"), url(/static/fonts/icomoon.9897630.woff) format("woff"), url(/static/img/icomoon.c533f8f.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
.icon-visible:before {
  content: "\E900"; }
.icon-invisible:before {
  content: "\E901"; }
.icon-adrive:before {
  content: "\E902"; }
.icon-close:before {
  content: "\E903"; }
.icon-edit:before {
  content: "\E904"; }
.icon-share:before {
  content: "\E905"; }
.icon-view:before {
  content: "\E906"; }
.icon-icon_knowledge:before {
  content: "\E907"; }
.icon-icon_mailbook:before {
  content: "\E908"; }
.icon-icon_meeting_room:before {
  content: "\E909"; }
.icon-arrow-right:before {
  content: "\E90A"; }
.icon-meeting:before {
  content: "\E90B"; }
.icon-contact:before {
  content: "\E90C"; }
.icon-knowledge:before {
  content: "\E90D"; }
.icon-secure:before {
  content: "\E90E"; }
.icon-partner:before {
  content: "\E90F"; }
.icon-pencil:before {
  content: "\E910"; }
.icon-copy:before {
  content: "\E911"; }
.icon-download:before {
  content: "\E912"; }
.icon-design:before {
  content: "\E913"; }
.icon-chevron-down:before {
  content: "\E914"; }
.icon-cross:before {
  content: "\EA0F"; }
.icon-checkmark:before {
  content: "\EA10"; }
/* GLOBAL */
:root {
  height: 100%;
  width: 100%;
  color: black;
  font-weight: 300;
  font-size: 16px; }
body {
  margin: 0;
  font-family: futura-pt, noto-sans-cjk-kr, sans-serif;
  font-style: normal;
  font-weight: 400;
  background: #fff;
  color: #2a3642;
  position: relative; }
.wrapper {
  position: relative;
  padding-bottom: 200px;
  min-height: 100%; }
.wrapper.no-footer {
    padding-bottom: 0; }
@media (max-width: 767.98px) {
    .wrapper {
      padding-bottom: 80px; } }
h2 {
  padding: 0 0 0.9375rem 0;
  margin: 0 0 1.25rem 0;
  border-bottom: 1px solid #818a91; }
@media (max-width: 767.98px) {
  input,
  select,
  textarea {
    font-size: 16px; } }
.container {
  max-width: 1280px;
  padding: 0 33px; }
@media (max-width: 991.98px) {
    .container {
      padding: 0 15px; } }
hr {
  margin: 50px 0 40px 0; }
.fade {
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease; }
footer {
  width: 100%;
  text-align: center;
  padding: 50px 0;
  position: absolute;
  bottom: 0; }
footer img {
    display: block;
    margin: 0 auto 10px; }
h1 {
  padding: 3.64583vw 0 20px; }
@media (max-width: 991.98px) {
    h1 {
      padding: 50px 0 20px; } }
.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100px;
  border-bottom: 1px solid #f4f5f6;
  /*---------------*/
  /* Dropdown menu */
  /*---------------*/ }
.nav-container .iasiance-primary-navbar {
    background-color: white !important;
    height: 100%;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    padding: 0 0 0 33px; }
@media (max-width: 991.98px) {
      .nav-container .iasiance-primary-navbar {
        padding: 0;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end; } }
.nav-container .iasiance-primary-navbar .navbar-toggler {
      display: none; }
.nav-container .iasiance-primary-navbar .collapse:not(.show) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
.nav-container .iasiance-primary-navbar .navbar-brand {
      padding: 0;
      margin: 0; }
.nav-container .iasiance-primary-navbar .navbar-brand img {
        width: 160px;
        margin-top: 15px; }
.nav-container .iasiance-primary-navbar .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      padding: 0;
      margin: 0;
      font-weight: 700;
      width: 72%; }
@media (max-width: 1200px) {
        .nav-container .iasiance-primary-navbar .navbar-nav {
          width: auto; } }
.nav-container .iasiance-primary-navbar .navbar-nav > li {
        margin-right: 10px;
        text-align: center;
        position: relative;
        min-width: 200px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
.nav-container .iasiance-primary-navbar .navbar-nav > li span.nav-link {
          -webkit-transition-delay: 0.2s;
                  transition-delay: 0.2s; }
@media (min-width: 992px) {
          .nav-container .iasiance-primary-navbar .navbar-nav > li:hover span.nav-link {
            color: inherit;
            -webkit-transition-delay: 0s;
                    transition-delay: 0s;
            background-color: #f9f9f9; }
          .nav-container .iasiance-primary-navbar .navbar-nav > li:hover .sub-nav {
            display: block;
            -webkit-transform: translateY(0);
                    transform: translateY(0); } }
.nav-container .iasiance-primary-navbar span.nav-link {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative;
      z-index: 999;
      color: #2a3642;
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      cursor: default; }
.nav-container .iasiance-primary-navbar span.nav-link.active {
        color: #2a3642; }
.nav-container .iasiance-primary-navbar span.nav-link.active span {
          display: inline-block;
          padding: 5px 0;
          border-bottom: 2px solid #2a3642; }
.nav-container .iasiance-primary-navbar span.nav-link .nav-link-icon {
        font-size: 1.3rem;
        display: none; }
.nav-container .iasiance-primary-navbar .user-block {
      padding: 0 33px;
      text-align: right;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      position: relative; }
.nav-container .iasiance-primary-navbar .user-block:hover {
        cursor: pointer; }
.nav-container .iasiance-primary-navbar .user-block i {
        margin-left: 10px;
        font-size: 10px; }
.nav-container .iasiance-primary-navbar .user-block.show {
        background-color: #f9f9f9; }
.nav-container .iasiance-primary-navbar .user-block.show i {
          display: inline-block;
          -webkit-transform: scaleY(-1);
                  transform: scaleY(-1); }
.nav-container .iasiance-primary-navbar .user-block .dropdown-header {
        display: none; }
.nav-container .iasiance-primary-navbar .user-block .dropdown-menu {
        background-color: #f9f9f9;
        text-align: right;
        border: none;
        margin: 0;
        padding: 0 0 20px 0;
        right: 0;
        top: 100%;
        min-width: 0;
        width: 100%;
        font-size: 1rem;
        text-align: center; }
.nav-container .iasiance-primary-navbar .user-block .dropdown-menu .dropdown-item {
          font-weight: 500;
          padding: 7px 0; }
.nav-container .iasiance-primary-navbar .user-block .user-block-avatar {
        width: 2.8125rem;
        border-radius: 50%;
        margin: 0 5px; }
@media (max-width: 991.98px) {
      .nav-container .iasiance-primary-navbar .navbar-brand {
        margin: 0;
        position: fixed;
        top: 0;
        left: 15px; }
        .nav-container .iasiance-primary-navbar .navbar-brand img {
          width: 100px; }
      .nav-container .iasiance-primary-navbar .navbar-toggler {
        display: block;
        position: fixed;
        top: 20px;
        right: 15px;
        border: none;
        outline: none;
        padding: 0; }
      .nav-container .iasiance-primary-navbar .burger-brick {
        height: 4px;
        width: 1.875rem;
        background: black;
        margin-top: 6px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
        border-radius: 5px; }
        .nav-container .iasiance-primary-navbar .burger-brick:first-child {
          margin: 0; }
      .nav-container .iasiance-primary-navbar .open .burger-brick:first-child {
        -webkit-transform: rotate(405deg);
                transform: rotate(405deg);
        margin-top: 14px; }
      .nav-container .iasiance-primary-navbar .open .burger-brick:last-child {
        -webkit-transform: rotate(-405deg);
                transform: rotate(-405deg);
        margin-top: -14px; }
      .nav-container .iasiance-primary-navbar .open .middle {
        -webkit-transform: scale(0);
                transform: scale(0); }
      .nav-container .iasiance-primary-navbar .navbar-nav {
        width: 100%;
        text-align: left;
        margin-top: 130px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: stretch;
            -ms-flex-align: stretch;
                align-items: stretch;
        max-height: calc(100vh - 130px);
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-touch-overflow: scroll;
        background-color: #fff;
        padding: 30px 15px; }
        .nav-container .iasiance-primary-navbar .navbar-nav.collapse:not(.show) {
          display: none; }
        .nav-container .iasiance-primary-navbar .navbar-nav li {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column; }
      .nav-container .iasiance-primary-navbar span.nav-link {
        margin: 30px 0 20px 0;
        font-size: 1.375rem;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; }
        .nav-container .iasiance-primary-navbar span.nav-link .nav-item a {
          color: #818a91; }
      .nav-container .iasiance-primary-navbar .user-block {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 65px;
        background-color: #f9f9f9;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 10px 15px;
        -webkit-box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
                box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07); }
        .nav-container .iasiance-primary-navbar .user-block:hover {
          cursor: default; }
        .nav-container .iasiance-primary-navbar .user-block .dropdown-menu {
          position: static;
          width: auto;
          padding: 0;
          text-align: right;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
          .nav-container .iasiance-primary-navbar .user-block .dropdown-menu > li {
            margin-left: 40px; }
          .nav-container .iasiance-primary-navbar .user-block .dropdown-menu .dropdown-item {
            font-weight: 400; }
            .nav-container .iasiance-primary-navbar .user-block .dropdown-menu .dropdown-item:active {
              background: none;
              color: inherit; }
      .nav-container .iasiance-primary-navbar .user-block-username {
        font-weight: 500;
        font-size: 1rem; }
      .nav-container .iasiance-primary-navbar .dropdown-selector {
        pointer-events: none; }
        .nav-container .iasiance-primary-navbar .dropdown-selector i {
          display: none; } }
.nav-container .sub-nav {
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    width: 100%;
    background-color: #f9f9f9;
    list-style: none;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(calc(-100% - 150px));
            transform: translateY(calc(-100% - 150px));
    left: 0;
    padding: 100px 10px 20px;
    text-align: center;
    -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
    overflow: hidden; }
@media (max-width: 991.98px) {
      .nav-container .sub-nav {
        position: relative;
        background-color: #fff;
        -webkit-transform: translate(0);
                transform: translate(0);
        -webkit-box-shadow: none;
                box-shadow: none;
        text-align: left;
        padding: 0; } }
.nav-container .sub-nav li {
      list-style: none;
      font-size: 1rem;
      font-weight: 450; }
.nav-container .sub-nav li a {
        display: inline-block;
        color: #2a3642;
        padding: 8px 0;
        font-size: 16px; }
.nav-container .sub-nav li a:hover {
          text-decoration: none; }
.nav-container .sub-nav li:last-child ul {
        padding-bottom: 0; }
.nav-container .sub-nav li ul {
        padding: 10px 0 18px;
        margin: 0; }
.nav-container .sub-nav li ul a {
          color: #818a91;
          font-weight: 400;
          font-size: 14px; }
.nav-container .sub-nav li ul a:hover {
            color: #2a3642; }
@media (max-width: 991.98px) {
  .nav-container {
    border-bottom: none;
    height: 65px; }
    .nav-container .sub-nav li {
      font-size: 1.125rem; }
      .nav-container .sub-nav li a {
        font-size: 1.125rem;
        font-weight: 400; }
      .nav-container .sub-nav li ul a {
        font-size: 1.125rem; } }
@media (min-width: 992px) {
  .user-block-username {
    display: none; } }
.login-block {
  background: #fff;
  width: 100%;
  padding: 3.125rem 0;
  height: 100vh;
  font-family: futura-pt, noto-sans-cjk-kr, sans-serif; }
.login-block a {
    margin: 0;
    padding: 0; }
.login-block .loginlogo {
    position: absolute;
    left: 33px;
    top: 15px;
    width: 160px; }
@media (max-width: 767.98px) {
      .login-block .loginlogo {
        left: 20px;
        top: 10px;
        width: 105px; } }
.login-block .login-header {
    z-index: 1;
    position: absolute;
    margin: auto;
    top: 2%; }
.login-block .login-header h1 {
      color: #2a3642;
      opacity: 0.04;
      font-weight: 600;
      font-size: calc(10vw); }
.login-block .login-container {
    z-index: 9;
    width: calc(100vw / 3.1);
    height: auto;
    background: #fff;
    border-radius: 30px;
    -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.07);
            box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.07);
    padding: 7.5rem; }
@media (max-width: 767.98px) {
      .login-block .login-container {
        padding: 10px; } }
.login-block .login-container .pinwheel {
      width: 60px; }
.login-block .login-container .login-sec {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative; }
.login-block .login-container .login-sec .login-sec-title {
        padding-top: 30px;
        padding-bottom: 30px;
        font-weight: 700;
        font-size: 28px;
        line-height: 103.52%;
        text-align: center;
        letter-spacing: 0.045em;
        color: #2a3642; }
.login-block .login-container .login-sec .btn {
        font-size: 20px;
        padding: 0.375rem 5rem;
        white-space: nowrap;
        border-radius: 10px;
        border: 1px solid #2a3642;
        color: #2a3642; }
.login-block .login-container .login-sec .btn .googlewhite {
          opacity: 0; }
.login-block .login-container .login-sec .btn:focus {
          -webkit-box-shadow: none;
                  box-shadow: none; }
.login-block .login-container .login-sec .btn:hover {
          background-color: #e43852;
          color: white;
          border-color: #e43852; }
.login-block .login-container .login-sec .btn:hover .googlecolor {
            opacity: 0; }
.login-block .login-container .login-sec .btn:hover .googlewhite {
            opacity: 1; }
.login-block .login-container .login-sec .btn .googlesignin {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: around;
              -ms-flex-pack: around;
                  justify-content: around;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
.login-block .login-container .login-sec .btn .googlesignin .googlecolor {
            height: 38px;
            margin: 4px 8px;
            position: relative; }
.login-block .login-container .login-sec .btn .googlesignin .googlewhite {
            position: absolute;
            height: 38px;
            margin: 4px 8px; }
.login-block .login-container .login-sec .btn .googlesignin p {
            margin: 0;
            font-weight: 400; }
@media (max-width: 1300px) {
    .login-block .login-container {
      width: calc(100vw / 2.75);
      padding: 4em;
      padding-top: 3.5em;
      padding-bottom: 3.5em; }
      .login-block .login-container .login-sec .btn {
        padding: 0 4rem; }
        .login-block .login-container .login-sec .btn .googlecolor {
          width: 24px;
          margin: 0px 8px; }
        .login-block .login-container .login-sec .btn .googlewhite {
          width: 24px;
          margin: 0px 8px; }
    .login-block .pinwheel {
      width: 50px; } }
@media (max-width: 600px) {
    .login-block .login-header {
      top: 8%; }
      .login-block .login-header h1 {
        font-size: calc(15vw); }
    .login-block .login-container {
      width: 100vw;
      -webkit-box-shadow: none;
              box-shadow: none; } }
#dashboard {
  padding-bottom: 170px; }
@media screen and (min-width: 1024px) and (max-height: 750px) {
    #dashboard {
      padding-bottom: 50px; } }
@media (max-width: 767.98px) {
    #dashboard {
      padding-bottom: 0; } }
#dashboard .container {
    position: relative; }
#dashboard .pinwheel {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 3.64583vw;
    right: 33px; }
@media (max-width: 991.98px) {
      #dashboard .pinwheel {
        display: none; } }
#dashboard .intro h1 {
    font-size: 50px;
    font-weight: 700; }
@media screen and (max-width: 1280px) {
      #dashboard .intro h1 {
        padding: 2.64583vw 0 20px;
        font-size: 36px; } }
#dashboard .intro h1 span {
      font-size: 0.52em;
      display: block;
      padding-bottom: 1.30208vw; }
#dashboard .intro p {
    font-size: 20px;
    font-size: 18px; }
@media (max-width: 575.98px) {
    #dashboard .intro {
      display: none; } }
#dashboard .quick-links {
    margin-top: 2.60417vw; }
@media (max-width: 991.98px) {
      #dashboard .quick-links {
        margin-top: 55px; }
        #dashboard .quick-links .row > div {
          width: 14%; } }
@media (max-width: 767.98px) {
      #dashboard .quick-links .row > div {
        width: 33%; } }
@media (max-width: 575.98px) {
      #dashboard .quick-links {
        margin-top: 0;
        padding: 0; }
        #dashboard .quick-links .row > div {
          width: 50%;
          height: 180px; }
          #dashboard .quick-links .row > div:last-child {
            width: 100%; }
        #dashboard .quick-links div[class^="col"]:nth-child(odd) .card {
          border-left: none; } }
#dashboard .card {
    color: #2a3642;
    background-color: #f9f9f9;
    border-radius: 10px;
    -webkit-box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 150px;
    min-width: 100px;
    min-height: 100px;
    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 1.125rem;
    font-weight: 600;
    white-space: nowrap;
    padding: 15px; }
#dashboard .card svg {
      margin-bottom: 10px;
      max-width: 100%; }
#dashboard .card:hover {
      cursor: pointer;
      background-color: #e43852;
      color: #fff;
      text-decoration: none; }
#dashboard .card:hover path {
        fill: #fff; }
@media (max-width: 1199.98px) {
      #dashboard .card {
        width: 120px;
        height: 120px;
        font-size: 0.875rem; } }
@media (max-width: 991.98px) {
      #dashboard .card {
        width: 60px;
        height: 60px;
        font-size: 0.7rem; } }
@media (max-width: 767.98px) {
      #dashboard .card {
        width: 150px;
        height: 150px;
        font-size: 0.875rem;
        margin: 0 auto 40px; } }
@media (max-width: 575.98px) {
      #dashboard .card {
        margin-bottom: 0;
        -webkit-box-shadow: none;
                box-shadow: none;
        border-radius: 0;
        width: 100%;
        height: 100%;
        font-size: 1rem;
        font-weight: 400;
        padding: 30px 0;
        border-bottom: 1px solid #d1d4d7;
        border-left: 1px solid #d1d4d7;
        background-color: #fff; } }
.dashboard-main-body {
  padding: 0; }
.dashboard-main-body-content {
  padding-top: 100px;
  padding-bottom: 20px; }
@media (max-width: 991.98px) {
    .dashboard-main-body-content {
      padding: 130px 0 50px 0; } }
.dashboard-main-body-content .card {
    border: none; }
.dashboard-main-body-content .card h2 {
      margin-top: 20px; }
.dashboard-main-body-content .card button:focus {
      -webkit-box-shadow: none;
              box-shadow: none; }
@media (max-width: 767.98px) {
      .dashboard-main-body-content .card .btn {
        width: 100%;
        font-size: 1.2rem;
        white-space: normal; } }
.contacts {
  background: #fff;
  min-height: 100%; }
.contacts h1 .form-group {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 50px; }
@media (max-width: 767.98px) {
      .contacts h1 .form-group {
        display: block;
        margin: 20px 0 0 0; } }
.contacts h1 .form-group .form-control {
      font-size: 1rem;
      border-bottom: 1px solid #000;
      width: 200px;
      background: url("/static/img/search.svg") no-repeat center right;
      padding-right: 20px;
      height: 30px; }
@media (max-width: 991.98px) {
    .contacts {
      padding: 0;
      margin-top: 0; }
      .contacts .filters.row {
        display: block; } }
@media (max-width: 767.98px) {
    .contacts input {
      font-size: 16px; } }
.contacts h1 {
    font-weight: 700; }
.contacts .table-container {
    height: 100%;
    overflow-y: scroll; }
.contacts .table-container::-webkit-scrollbar {
      width: 5px; }
.contacts .table-container::-webkit-scrollbar-track {
      background: #fff; }
.contacts .table-container::-webkit-scrollbar-thumb {
      background: #e1e3e4; }
.contacts .table-container::-webkit-scrollbar-thumb:hover {
      background: #d1d4d7; }
.contacts .list-style-icon {
    margin: 5px 0.625rem 5px 0px; }
.contacts .table {
    margin-bottom: 0; }
.contacts .table thead {
      table-layout: fixed; }
.contacts .table thead th {
        background: rgba(76, 94, 112, 0.05);
        font-weight: 450;
        position: relative;
        border-bottom: 1px solid #818a91;
        border-top: 1px solid #d1d4d7;
        padding: 18px 25px; }
.contacts .table thead th.sortable:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 5px 0 5px;
          border-color: #2a3642 transparent transparent transparent;
          display: inline-block;
          margin-left: 30px; }
.contacts .table thead th:focus {
          outline: none; }
.contacts .table thead th.phone-column {
          white-space: nowrap; }
.contacts .table thead div {
        display: inline-block; }
.contacts .table tbody tr:hover {
      cursor: copy; }
.contacts .table tr {
      background: #fff; }
.contacts .table tr:nth-child(even) {
        background: rgba(227, 61, 80, 0.07); }
.contacts .table tr:nth-child(even) td {
          border-left: 1px solid #fff; }
.contacts .table td {
      vertical-align: middle;
      padding: 18px 25px;
      border-top: none;
      border-left: 1px solid #f4f5f6; }
.contacts .table td.avatar-column img {
        border-radius: 50%; }
.contacts .table td.name-column {
        width: 10%;
        white-space: nowrap; }
.contacts .table td.email-column, .contacts .table td.phone-column {
        width: 15%; }
.contacts .table td div {
        overflow-wrap: break-word;
        width: 100%;
        display: table;
        table-layout: fixed;
        word-wrap: break-word; }
@media (max-width: 991.98px) {
      .contacts .table th {
        display: none; }
      .contacts .table tr:nth-child(odd) td {
        border: none; }
      .contacts .table td {
        border: none;
        padding: 8px 25px; }
        .contacts .table td.collapsible {
          float: left;
          width: 100%; }
          .contacts .table td.collapsible.name-column {
            font-weight: bold; }
          .contacts .table td.collapsible:first-child {
            padding-top: 20px; }
          .contacts .table td.collapsible:last-child {
            padding-bottom: 20px; }
        .contacts .table td.name-column {
          width: 100%; }
        .contacts .table td:not(.avatar-column):not(.name-column):not(.email-group-column):not(.members-column) {
          display: none; } }
@media (max-width: 991.98px) {
  .contacts {
    background: none; }
  .filters {
    background: #fff;
    padding: 10px 0 20px 0;
    margin: 0; }
  .contact-detail {
    position: relative;
    text-align: center;
    font-size: 1.5rem;
    padding: 40px 0; }
    .contact-detail .avatar {
      border-radius: 50%; }
    .contact-detail .close {
      float: none;
      position: absolute;
      z-index: 8888;
      width: 30px;
      height: 30px;
      top: 20px;
      right: 10px; }
      .contact-detail .close:before, .contact-detail .close:after {
        content: "";
        width: 30px;
        height: 1px;
        border-top: 1px solid black;
        position: absolute;
        top: 12px;
        left: 0;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
      .contact-detail .close:after {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
    .contact-detail .row {
      margin: 0; }
    .contact-detail h2 {
      font-size: 1.5em;
      margin-top: 15px; }
    .contact-detail .phones {
      margin-bottom: 15px; }
      .contact-detail .phones i {
        margin-right: 8px; }
    .contact-detail h5 {
      font-size: 0.85em;
      padding-top: 30px;
      padding-bottom: 10px;
      border-bottom: 1px solid #818a91; }
    .contact-detail p.groups {
      font-size: 0.75em; } }
.cont-calendar {
  padding-right: 0;
  width: 94%; }
.calendar {
  margin-bottom: 20px; }
@media (max-width: 991.98px) {
    .calendar {
      margin-bottom: 43px; } }
.calendar .row {
    position: relative; }
.calendar .wrapperSpinner {
    width: auto !important;
    top: auto;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
.calendar .hours {
    position: absolute;
    right: calc(100% + 10px);
    top: 60px;
    font-size: 0.875rem;
    font-weight: 500; }
.calendar .hours span {
      display: block;
      padding: 0 10px 39px 0;
      text-align: right; }
@media (max-width: 991.98px) {
      .calendar .hours {
        right: auto;
        left: 0; } }
.resource-calendar-container:nth-child(2) .current-time:before {
  content: "";
  width: 9px;
  height: 9px;
  position: absolute;
  top: -5px;
  left: -15px;
  background-color: #000;
  border-radius: 50%; }
.resource-calendar-container:nth-child(2) .current-time:after {
  content: "";
  width: 15px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: -1px;
  left: -10px; }
.resource-calendar-container:last-child .timetable {
  border-right: none; }
.resource-calendar-container h3 {
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0 30px 10px; }
.resource-calendar-container h3 .roomcolor {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 2px; }
.resource-calendar-container#r-meeting .roomcolor {
  background-color: #f36375; }
.resource-calendar-container#r-meeting .busy {
  border-left-color: #f36375; }
.resource-calendar-container#r-meeting .free:hover {
  border-left-color: rgba(243, 99, 117, 0.5); }
.resource-calendar-container#r-studio .roomcolor {
  background-color: #b58dd6; }
.resource-calendar-container#r-studio .busy {
  border-left-color: #b58dd6; }
.resource-calendar-container#r-studio .free:hover {
  border-left-color: rgba(181, 141, 214, 0.5); }
.resource-calendar-container#r-creative .roomcolor {
  background-color: #516478; }
.resource-calendar-container#r-creative .busy {
  border-left-color: #516478; }
.resource-calendar-container#r-creative .free:hover {
  border-left-color: rgba(81, 100, 120, 0.5); }
.resource-calendar-container#r-3f .roomcolor {
  background-color: #61c7c7; }
.resource-calendar-container#r-3f .busy {
  border-left-color: #61c7c7; }
.resource-calendar-container#r-3f .free:hover {
  border-left-color: rgba(97, 199, 199, 0.5); }
.resource-calendar-container#r-phone-booth .roomcolor {
  background-color: #61c7c7; }
.resource-calendar-container#r-phone-booth .busy {
  border-left-color: #61c7c7; }
.resource-calendar-container#r-phone-booth .free:hover {
  border-left-color: rgba(97, 199, 199, 0.5); }
.resource-calendar-container .btn {
  margin: 0 0 20px 10px; }
@media (max-width: 767.98px) {
    .resource-calendar-container .btn {
      width: 100%;
      padding: 10px;
      font-size: 1.25rem;
      margin: 0; } }
.resource-calendar-container .timetable {
  border-right: 1px solid #e1e3e4;
  position: relative;
  background-image: -ms-linear-gradient(0deg, transparent 60px, #e1e3e4 0, transparent 61px), -ms-linear-gradient(#e1e3e4 1px, transparent 0);
  -webkit-background-size: 100% 60px;
  -moz-background-size: 100% 60px;
  background-image: linear-gradient(0deg, transparent 60px, #e1e3e4 0, transparent 61px), linear-gradient(#e1e3e4 1px, transparent 0);
  background-size: 100% 60px; }
.resource-calendar-container .timetable .current-time {
    border-top: 1px solid #000;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    -webkit-transition: top 100ms linear;
    transition: top 100ms linear;
    background-color: rgba(76, 94, 112, 0.05); }
@media (max-width: 991.98px) {
  .resource-calendar-container {
    left: 0;
    margin: 50px 0 60px 0;
    padding: 20px 15px 0 15px !important; }
    .resource-calendar-container.classes h3 {
      width: 50%;
      max-width: 50%; }
    .resource-calendar-container:not(.active) {
      position: absolute;
      background: none; }
      .resource-calendar-container:not(.active) h3 {
        background: #fff;
        border-bottom: 1px solid #d1d4d7; }
      .resource-calendar-container:not(.active) .current-time {
        display: none; }
      .resource-calendar-container:not(.active) .current-time {
        display: none; }
      .resource-calendar-container:not(.active) .schedule-card,
      .resource-calendar-container:not(.active) .btn {
        display: none; }
    .resource-calendar-container h3 {
      font-size: 1rem;
      position: absolute;
      top: -69px;
      left: 7%;
      text-align: center;
      width: 20%;
      padding: 15px 5px;
      margin: 0;
      border-bottom: 1px solid #2a3642;
      text-transform: uppercase; }
    .resource-calendar-container:nth-child(3) h3 {
      left: 25%; }
    .resource-calendar-container:nth-child(4) h3 {
      left: 43%; }
    .resource-calendar-container:nth-child(5) h3 {
      left: 60%; }
    .resource-calendar-container:nth-child(6) h3 {
      left: 78%; }
    .resource-calendar-container:nth-child(2).classes h3 {
      left: 0; }
    .resource-calendar-container:nth-child(3).classes h3 {
      left: 50%; }
    .resource-calendar-container .timetable {
      width: calc(100% - 40px);
      float: right;
      border-right: none; } }
@media (max-width: 767.98px) {
  .resource-calendar-container h3 {
    font-size: 0.8rem;
    position: absolute;
    top: -69px;
    left: -3%;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 24%;
    padding: 15px 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .resource-calendar-container:nth-child(3) h3 {
    left: 21%; }
  .resource-calendar-container:nth-child(4) h3 {
    left: 41%; }
  .resource-calendar-container:nth-child(5) h3 {
    left: 64%; }
  .resource-calendar-container:nth-child(6) h3 {
    left: 75%; }
  .resource-calendar-container:nth-child(2).classes h3 {
    left: -2%; }
  .resource-calendar-container:nth-child(3).classes h3 {
    left: 48%; } }
.resource-filter {
  width: 100%;
  padding: 30px 0 20px 0;
  position: relative;
  margin-bottom: 1rem; }
.resource-filter .form-group {
    margin: 0; }
.resource-filter .form-inline {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
.resource-filter label {
    font-size: 1rem;
    margin-bottom: 0; }
.resource-filter .custom-switch {
    margin-top: 16px; }
.resource-filter .custom-checkbox {
    position: absolute;
    right: 15px; }
@media (max-width: 575.98px) {
      .resource-filter .custom-checkbox {
        position: static;
        width: 100%;
        padding-top: 10px; } }
.resource-filter .prev-day,
  .resource-filter .next-day,
  .resource-filter .refresh {
    cursor: pointer;
    background-color: #f4f5f6;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center; }
.resource-filter .refresh {
    margin-left: 20px; }
.resource-filter .vdatetime input {
    cursor: pointer;
    display: inline-block;
    width: 250px;
    text-align: center;
    font-size: 1.875rem;
    font-weight: 700;
    color: #2a3642;
    text-transform: uppercase;
    padding: 0.5rem 0.8rem;
    margin: 0 5px;
    border: none; }
@media (max-width: 767.98px) {
      .resource-filter .vdatetime input {
        font-size: 24px;
        width: 200px; } }
.resource-filter .vdatetime .vdatetime-popup__header {
    background: #e43852; }
.resource-filter .vdatetime .vdatetime-calendar__month__day--selected > span > span {
    background: #e43852; }
.resource-filter .vdatetime .vdatetime-popup__actions__button {
    color: #e43852; }
.card {
  background-color: transparent;
  overflow: hidden; }
.schedule-card {
  height: calc(100% + 1px);
  top: 0;
  padding: 5px 10px;
  border-radius: 0; }
.schedule-card.d-30 {
    /* span{
      &:before{
        content:', ';
      }
    } */ }
.schedule-card.d-30 h5 {
      display: inline; }
.schedule-card.free {
    position: relative;
    background-color: transparent;
    border-left: 5px transparent solid; }
.schedule-card.free:hover {
      background: #eaebec; }
.schedule-card.free:hover:before {
        content: "Book now";
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        font-size: 1rem;
        color: #fff;
        text-align: center;
        background-color: #e43852;
        border-radius: 5px;
        padding: 7px 15px; }
.schedule-card.busy {
    background-color: #fff;
    border-left: 5px #d1d4d7 solid;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 99; }
.schedule-card.busy .infos {
      white-space: nowrap;
      font-weight: 600;
      font-size: 0.875rem;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translate(-50%, -98%);
              transform: translate(-50%, -98%);
      z-index: 998;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 2px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
              box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      padding: 10px 15px;
      text-transform: capitalize;
      -webkit-transition: opacity 300ms ease;
      transition: opacity 300ms ease; }
@media (max-width: 991.98px) {
        .schedule-card.busy .infos {
          opacity: 1;
          position: static;
          padding: 3px 0;
          -webkit-box-shadow: none;
                  box-shadow: none;
          background: none;
          font-size: 0.75rem;
          display: block;
          -webkit-transform: none;
                  transform: none; } }
.schedule-card.busy:hover {
      overflow: visible;
      min-height: 100%;
      height: auto;
      z-index: 9999; }
.schedule-card.busy:hover .infos {
        opacity: 1; }
.schedule-card .card-body {
    padding: 0; }
.schedule-card .card-body h5 {
      font-size: 0.875rem;
      margin: 0;
      font-weight: 500; }
@media (max-width: 991.98px) {
  .calendar-card {
    width: unset;
    margin: 0.625rem 0; } }
.custom-control-label:before {
  background-color: #e1e3e4;
  border: none; }
.custom-control-label:after {
  top: 5px;
  left: -22px; }
.custom-switch .custom-control-label {
  padding: 3px 0 0 35px; }
.custom-switch .custom-control-label::before {
  width: 54px;
  height: 1.625rem;
  border-radius: 20px; }
.custom-switch .custom-control-label::after {
  background-color: #e43852;
  width: calc(1.625rem - 4px);
  height: calc(1.625rem - 4px);
  border-radius: 50%;
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1);
          box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1); }
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #e43852; }
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(1.75rem);
          transform: translateX(1.75rem);
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #fff; }
.tabs .nav-tabs {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: none; }
.tabs .nav-tabs .nav-item {
    width: 25%;
    text-align: center;
    font-size: 1rem; }
.tabs .nav-tabs .nav-link {
    text-transform: capitalize; }
.tabs .nav-tabs .nav-link.active,
  .tabs .nav-tabs .nav-item .nav-link {
    color: #2a3642;
    border: none; }
.tabs .nav-tabs .nav-link.active,
  .tabs .nav-tabs .nav-item.show .nav-link {
    background-color: inherit;
    border-bottom: 1px solid #34414c; }
.tabs .tab-content {
  margin-top: 2rem !important; }
.tabs .tab-content .tab-pane {
    outline: none; }
.tabs .tab-content .tab-pane .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
#knowledgesBlock {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
#knowledgesBlock h1 {
    font-weight: 700; }
@media (max-width: 767.98px) {
      #knowledgesBlock h1 {
        padding-top: 16px;
        font-size: 28px; } }
#knowledgesBlock .links-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border: none; }
#knowledgesBlock .links-block h2 {
      margin-top: 20px; }
@media (max-width: 767.98px) {
      #knowledgesBlock .links-block {
        display: block; } }
#knowledgesBlock .card-category {
    width: 24%;
    text-align: center;
    background-color: white;
    -webkit-box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.12);
            box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.12);
    border-top: 3px solid #2a3642;
    padding: 0 24px;
    padding-bottom: 40px;
    height: 620px;
    overflow-y: scroll; }
#knowledgesBlock .card-category::-webkit-scrollbar {
      height: 5px;
      width: 5px; }
#knowledgesBlock .card-category::-webkit-scrollbar-track {
      background: #fff; }
#knowledgesBlock .card-category::-webkit-scrollbar-thumb {
      background: #e1e3e4; }
#knowledgesBlock .card-category::-webkit-scrollbar-thumb:hover {
      background: #d1d4d7; }
@media (min-width: 992px) {
      #knowledgesBlock .card-category {
        position: relative; } }
@media (max-width: 767.98px) {
      #knowledgesBlock .card-category {
        padding-bottom: 20px;
        width: 100%;
        margin: 20px 0;
        height: auto;
        overflow-y: unset; } }
@media (min-width: 992px) {
      #knowledgesBlock .card-category .card-title {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: white; } }
@media (max-width: 767.98px) {
      #knowledgesBlock .card-category .card-title {
        margin-bottom: 0;
        position: relative; }
        #knowledgesBlock .card-category .card-title .togglechev {
          position: absolute;
          right: 0;
          top: 8px;
          width: 20px; } }
#knowledgesBlock .card-category hr {
      margin: 20px 0; }
#knowledgesBlock .card-category h2 {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      border-bottom: none !important;
      pointer-events: none; }
@media (min-width: 992px) {
        #knowledgesBlock .card-category h2 {
          padding-top: 30px;
          margin-top: 0;
          padding-bottom: 20px;
          margin-bottom: 0; } }
@media (max-width: 767.98px) {
        #knowledgesBlock .card-category h2 {
          margin-top: 20px;
          pointer-events: auto;
          margin-bottom: 0;
          padding-bottom: 0;
          font-size: 20px; } }
#knowledgesBlock .card-category .starpin {
      padding-left: 5px;
      cursor: pointer; }
@media (max-width: 767.98px) {
      #knowledgesBlock .card-category .linklist {
        margin-top: 20px; } }
@media (min-width: 768px) {
      #knowledgesBlock .card-category .togglechev {
        display: none; } }
#knowledgesBlock .card-category ul:not(.nav-tabs) {
      width: 100%;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      list-style: none;
      color: #2a3642;
      padding: 0;
      margin-bottom: 0; }
#knowledgesBlock .card-category ul:not(.nav-tabs) li:not(.nav-item) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        font-size: 16px;
        background: #f6f7f8;
        width: 100%;
        margin: 5px 0;
        padding: 13px 16px;
        border: none;
        font-weight: 400;
        text-align: left; }
#knowledgesBlock .card-category ul:not(.nav-tabs) li:not(.nav-item):hover {
          background-color: #fdf1f3; }
#knowledgesBlock .card-category ul:not(.nav-tabs) .starredlink {
        background-color: #e1e3e5; }
#knowledgesBlock .card-category ul:not(.nav-tabs) .starredlink:hover {
          background-color: #fdf1f3; }
#knowledgesBlock .card-category ul:not(.nav-tabs) a {
        text-decoration: none;
        color: #2a3642; }
#knowledgesBlock .card-category ul:not(.nav-tabs) .pinwrap {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: block; }
#knowledgesBlock .card-category .list-style-icon {
      margin: 5px 0.625rem 5px 0px; }
#knowledgesBlock .card-category .list-style-image {
      width: 1rem;
      margin-right: 0.625rem; }
#knowledgesBlock .card-category .knowledge-sub-block {
      background-color: white;
      border: 1px solid #d1d4d7;
      padding: 1.25rem;
      margin: 0 0 1.25rem 0; }
#knowledgesBlock .card-category .knowledge-sub-block:last-child {
        margin: 0; }
@media (min-width: 576px) {
    #knowledgesBlock .modal-dialog {
      max-width: 80%;
      margin: 1.75rem auto; } }
#knowledgesBlock .tabs .tab-content {
    margin-top: 0 !important; }
#knowledgesBlock .nav-tabs .nav-item {
    margin-bottom: 0; }
.partnership-wrapper {
  width: 90%;
  margin: 0 auto; }
.partnership {
  background: #fff;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100%;
  position: relative; }
@media (max-width: 991.98px) {
    .partnership {
      padding: 0;
      margin-top: 0; }
      .partnership .filters {
        display: block; } }
@media (max-width: 991.98px) {
    .partnership .searchfilter-wrap {
      padding-left: 0;
      padding-right: 0; } }
.partnership .rel-checkbox-group {
    text-align: right; }
@media (max-width: 991.98px) {
      .partnership .rel-checkbox-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding-left: 0;
        padding-right: 0; } }
.partnership .relevance-checkbox {
    padding-top: 10px;
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    margin-left: 40px;
    font-size: 18px; }
@media (max-width: 991.98px) {
      .partnership .relevance-checkbox {
        margin-left: 0; } }
.partnership .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: #fff;
    border: 2px solid #d1d4d7; }
.partnership .custom-control-label::after {
    background-size: 70%;
    border-color: #e43852; }
.partnership .custom-control-input:checked ~ .custom-control-label::after {
    border: #e43852;
    border: #e43852; }
.partnership .table-container {
    height: 100%;
    overflow-y: scroll; }
.partnership .table-container::-webkit-scrollbar {
      height: 12px;
      width: 12px; }
.partnership .table-container::-webkit-scrollbar-track {
      background: #f9f9f9; }
.partnership .table-container::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: #d1d4d7; }
.partnership .table-container::-webkit-scrollbar-thumb:hover {
      background: #9fa1a3; }
.partnership .table-container .new-badge {
      margin-left: 5px; }
@media (max-width: 767.98px) {
      .partnership .table-container {
        margin-bottom: 50px; } }
@media (max-width: 991.98px) {
    .partnership .searchfilters {
      margin-bottom: 0; } }
.partnership .searchbar {
    margin-bottom: 0; }
.partnership .searchbar h3 {
      border: none;
      font-weight: bold;
      -webkit-transform: translateY(10px);
              transform: translateY(10px); }
.partnership .searchbar .searchbar-input {
      margin-right: 0;
      margin-left: 15px;
      margin-bottom: 8px;
      border-bottom: 1.5px solid black;
      position: relative; }
.partnership .searchbar .searchbar-input::-webkit-input-placeholder {
        padding-bottom: 0; }
.partnership .searchbar .searchbar-input::-ms-input-placeholder {
        padding-bottom: 0; }
.partnership .searchbar .searchbar-input::placeholder {
        padding-bottom: 0; }
.partnership .searchbar .magicon {
      position: absolute;
      top: 50%;
      right: 25px;
      margin-top: -10px;
      color: #d1d4d7; }
.partnership .highlight {
    background-color: yellow; }
.partnership .table {
    text-align: center;
    margin-bottom: 0; }
.partnership .table thead {
      table-layout: fixed; }
.partnership .table thead th {
        white-space: nowrap;
        vertical-align: middle;
        background: #f4f5f6;
        font-weight: 600;
        position: relative;
        border-bottom: 2px solid #818a91;
        border-top: 1px solid #d1d4d7;
        border-right: 1px solid #d1d4d7;
        -webkit-transform: translateY(0);
                transform: translateY(0); }
.partnership .table thead th.sortable:before, .partnership .table thead th.sortable:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          right: 10px;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
.partnership .table thead th.sortable:before {
          border-width: 0 4px 4px 4px;
          border-color: transparent transparent #000 transparent;
          top: 45%; }
.partnership .table thead th.sortable:after {
          border-width: 4px 4px 0 4px;
          border-color: #000 transparent transparent transparent;
          top: 55%; }
.partnership .table thead th:focus {
          outline: none; }
.partnership .table thead th:last-child {
        border-right: none; }
.partnership .table thead span.has-dropdown {
        cursor: pointer; }
.partnership .table thead span.has-dropdown:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 5px 0 5px;
          border-color: #000000 transparent transparent transparent;
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px; }
.partnership .table tbody td span {
      pointer-events: none; }
.partnership .table tr {
      cursor: pointer;
      background: #fff;
      font-size: 0.9rem;
      height: 60px; }
.partnership .table tr:focus, .partnership .table tr:active {
        background-color: rgba(255, 98, 98, 0.123); }
.partnership .table td {
      text-align: center;
      border-top: none;
      border-right: 1px solid #d1d4d7;
      border-bottom: 1px solid #d1d4d7;
      vertical-align: middle;
      padding: 12px; }
.partnership .table td.partner-col {
        width: 15%; }
.partnership .table td div {
        overflow-wrap: break-word;
        table-layout: fixed;
        word-wrap: break-word; }
.partnership .table td .url-font-black {
        color: black; }
.partnership .table td:last-child {
      border-right: none;
      white-space: nowrap; }
@media (max-width: 1199.98px) {
      .partnership .table th:not(.partner-col):not(.service_type-col):not(.description-col):not(.area-col):not(.relevance-col):not(.status-col) {
        display: none; }
      .partnership .table td {
        border-right: none;
        padding: 10px 0; }
        .partnership .table td.partner-col {
          width: 30%; }
        .partnership .table td:not(.partner-col):not(.service_type-col):not(.description-col):not(.area-col):not(.relevance-col):not(.status-col) {
          display: none; } }
@media (max-width: 991.98px) {
      .partnership .table th:not(.partner-col):not(.service_type-col):not(.area-col):not(.relevance-col):not(.status-col) {
        display: none; }
      .partnership .table td {
        border-right: none;
        padding: 10px 0; }
        .partnership .table td.partner-col {
          width: 30%; }
        .partnership .table td:not(.partner-col):not(.service_type-col):not(.area-col):not(.relevance-col):not(.status-col) {
          display: none; } }
@media (max-width: 767.98px) {
      .partnership .table th:not(.partner-col) {
        display: none; }
      .partnership .table td {
        border-right: none;
        padding: 10px 0; }
        .partnership .table td:not(.partner-col) {
          display: none; } }
@media screen and (max-width: 359px) {
      .partnership .table th:not(.partner-col) {
        display: none; }
      .partnership .table td {
        border-right: none;
        padding: 10px 0; }
        .partnership .table td:not(.partner-col) {
          display: none; } }
@media (max-width: 991.98px) {
  .partnership {
    background: none; }
  .filters {
    background: #fff;
    padding: 10px 0 20px 0;
    margin: 0; }
  .partnership-wrapper {
    padding: 0; }
  .partnership-detail {
    position: relative;
    text-align: center;
    font-size: 1.5rem; }
    .partnership-detail .close {
      float: none;
      position: absolute;
      z-index: 8888;
      width: 30px;
      height: 30px;
      top: 0px;
      right: 10px; }
      .partnership-detail .close:before, .partnership-detail .close:after {
        content: "";
        width: 30px;
        height: 1px;
        border-top: 1px solid black;
        position: absolute;
        top: 12px;
        left: 0;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
      .partnership-detail .close:after {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
    .partnership-detail .row {
      margin: 0; }
    .partnership-detail .partner-info {
      padding: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #818a91; }
      .partnership-detail .partner-info p {
        font-size: 2rem; }
    .partnership-detail .font-small {
      font-size: 0.8em; }
    .partnership-detail .internal-info {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #818a91; }
    .partnership-detail .list-style-icon {
      margin: 5px 0.625rem 5px 0px; } }
#p-invisible-icon {
  font-size: 24px; }
#p-visible-icon {
  font-size: 14px; }
.p-icons:hover {
  cursor: pointer; }
.domains {
  background: #fff;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100%;
  position: relative; }
@media (max-width: 991.98px) {
    .domains {
      padding: 0;
      margin-top: 0; }
      .domains .filters.row {
        display: block; } }
.domains .table-container {
    height: 100%;
    overflow-y: scroll; }
.domains .table {
    margin-bottom: 0; }
.domains .table thead {
      table-layout: fixed; }
.domains .table thead th {
        background: #fff;
        font-weight: normal;
        position: relative;
        border-bottom: 1px solid #818a91;
        border-top: 1px solid #818a91;
        -webkit-transform: translateY(0);
                transform: translateY(0); }
.domains .table thead th.sortable:before, .domains .table thead th.sortable:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          right: 10px;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
.domains .table thead th.sortable:before {
          border-width: 0 4px 4px 4px;
          border-color: transparent transparent #000 transparent;
          top: 40%; }
.domains .table thead th.sortable:after {
          border-width: 4px 4px 0 4px;
          border-color: #000 transparent transparent transparent;
          top: 60%; }
.domains .table thead th:focus {
          outline: none; }
.domains .table tr {
      background: #fff; }
.domains .table tr:nth-child(odd) {
        background: #f4f5f6; }
.domains .table tr:nth-child(odd) td {
          border-left: 1px solid #fff; }
.domains .table td {
      border-top: none;
      border-left: 1px solid #f4f5f6; }
.domains .table td.client-column {
        width: 10%;
        white-space: nowrap; }
.domains .table td div {
        overflow-wrap: break-word;
        width: 100%;
        display: table;
        table-layout: fixed;
        word-wrap: break-word; }
@media (max-width: 991.98px) {
      .domains .table th:not(.client-column):not(.domain-column):not(.ssl-column) {
        display: none; }
      .domains .table tr:nth-child(odd) td {
        border: none; }
      .domains .table td {
        border: none; }
        .domains .table td.client-column {
          width: 20%; }
        .domains .table td.ssl-column {
          width: 25%; }
        .domains .table td:not(.client-column):not(.domain-column):not(.ssl-column) {
          display: none; } }
@media (max-width: 991.98px) {
  .domains {
    background: none; }
  .filters {
    background: #fff;
    padding: 10px 0 20px 0;
    margin: 0; }
  .domains-wrapper {
    padding: 0; }
  .domain-detail {
    position: relative;
    text-align: center;
    font-size: 1.5rem; }
    .domain-detail .close {
      float: none;
      position: absolute;
      z-index: 8888;
      width: 30px;
      height: 30px;
      top: 0px;
      right: 10px; }
      .domain-detail .close:before, .domain-detail .close:after {
        content: "";
        width: 30px;
        height: 1px;
        border-top: 1px solid black;
        position: absolute;
        top: 12px;
        left: 0;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
      .domain-detail .close:after {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
    .domain-detail .row {
      margin: 0; }
    .domain-detail h2 {
      font-size: 1.5em;
      margin-top: 15px; }
    .domain-detail a {
      font-size: 1.2em; }
    .domain-detail .ssl-info {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #818a91; }
    .domain-detail .list-style-icon {
      margin: 5px 0.625rem 5px 0px; } }
#DesignSystem .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 40vh;
  min-height: 300px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
#DesignSystem h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 3.75rem;
  font-weight: 700;
  color: #2a3642;
  letter-spacing: 1rem;
  margin: 1rem 0; }
@media (max-width: 767.98px) {
    #DesignSystem h1 {
      font-size: 2rem;
      letter-spacing: 0.5rem; } }
#DesignSystem h2 {
  font-size: 2.5rem;
  font-weight: 700;
  border: none;
  margin-top: 2rem; }
#DesignSystem h3 {
  font-size: 1.625em;
  font-weight: 700; }
#DesignSystem p {
  font-size: 1.125rem; }
@media (max-width: 767.98px) {
    #DesignSystem p {
      font-size: 1rem; } }
#DesignSystem p.sub {
    font-size: 1.25rem;
    text-align: center;
    max-width: 1280px;
    margin: 0 auto; }
@media (max-width: 767.98px) {
      #DesignSystem p.sub {
        font-size: 1rem; } }
#DesignSystem strong.red {
  color: #e43852; }
#DesignSystem .logo-preview {
  width: 100%;
  margin-bottom: 3rem;
  background-color: #f8f9fa;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
@media (max-width: 767.98px) {
    #DesignSystem .logo-preview {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }
#DesignSystem .logo-preview svg {
    margin: 6% 5rem 7.5%;
    max-width: 100%; }
@media (max-width: 767.98px) {
      #DesignSystem .logo-preview svg {
        margin: 10px 0;
        padding: 0 10%; } }
#DesignSystem .logo-preview.dark {
    background-color: #2a3642; }
#DesignSystem .logo-preview.dark g:not(.pinwheel) path {
      fill: #fff !important; }
#DesignSystem .colorcode {
  padding: 50px 0 100px 0;
  color: #fff;
  font-size: 1.125rem; }
#DesignSystem .colorcode div {
    padding: 15px 10%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
@media (max-width: 767.98px) {
      #DesignSystem .colorcode div {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
#DesignSystem .colorcode h4,
  #DesignSystem .colorcode p {
    width: 20%;
    display: inline-block;
    font-size: 1.125rem;
    margin-bottom: 0; }
@media (max-width: 767.98px) {
      #DesignSystem .colorcode h4,
      #DesignSystem .colorcode p {
        width: 100%; } }
#DesignSystem .colorcode h4 {
    text-transform: uppercase;
    font-weight: 700; }
#DesignSystem .colorcode span {
    padding-right: 15px; }
#DesignSystem .colorcode .navy {
    background-color: #2a3642; }
#DesignSystem .colorcode .red {
    background-color: #e43852; }
#DesignSystem .card {
  height: 100%;
  border: 1px solid #d1d4d7;
  font-size: 1.125rem;
  display: block;
  -webkit-box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.07); }
#DesignSystem .card img {
    max-width: 100%;
    height: auto;
    border-bottom: 1px solid #d1d4d7; }
#DesignSystem .card .text {
    padding: 40px 25px; }
#DesignSystem .do {
  width: 20px;
  height: 20px;
  border: 2px solid #1cd67c;
  border-radius: 50%;
  margin-bottom: 15px; }
#DesignSystem .donot {
  margin-bottom: 15px;
  width: 20px;
  height: 20px;
  position: relative; }
#DesignSystem .donot:before, #DesignSystem .donot:after {
    content: "";
    width: 24px;
    height: 2px;
    background-color: #e02020;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50px; }
#DesignSystem .donot:before {
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
#DesignSystem .donot:after {
    left: auto;
    right: 2px;
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
#DesignSystem .banner {
  background-color: #2a3642;
  text-align: center;
  margin-bottom: 5%; }
#DesignSystem .banner img {
    max-width: 100%; }
#DesignSystem .colorcard {
  border: 1px solid #e1e3e4;
  border-bottom-right-radius: 30px;
  -webkit-box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.07);
  min-height: 260px;
  height: 100%; }
#DesignSystem .colorcard > div {
    height: 140px;
    padding: 15px 20px;
    font-size: 1.5rem;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    color: #fff; }
@media (max-width: 767.98px) {
      #DesignSystem .colorcard > div {
        font-size: 1.125rem; } }
#DesignSystem .colorcard > div.red {
      background-color: #e43852; }
#DesignSystem .colorcard > div.navy {
      background-color: #2a3642; }
#DesignSystem .colorcard > div.red-pantone {
      background-color: #dd5063; }
#DesignSystem .colorcard > div.navy-pantone {
      background-color: #3e5266; }
#DesignSystem .colorcard > div.red2 {
      background-color: #e43b50; }
#DesignSystem .colorcard > div.navy2 {
      background-color: #3e4d5c; }
#DesignSystem .colorcard > div.pink {
      background-color: #ed7e8c; }
#DesignSystem .colorcard > div.lightblue {
      background-color: #749dc0; }
#DesignSystem .colorcard > div.darkblue {
      background-color: #4d6e8b; }
#DesignSystem .colorcard > div.gray {
      background-color: #9ca4ab; }
#DesignSystem .colorcard > div img {
      max-width: 100%; }
#DesignSystem .colorcard p {
    padding: 20px 20px;
    min-height: 70px;
    position: relative; }
#DesignSystem .colorcard p:before {
      content: "\E911";
      font-family: "icomoon";
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: copy; }
#DesignSystem .colorcard p strong:hover {
      cursor: copy; }
#DesignSystem.typography .banner {
  background-color: #f4f5f6;
  text-align: left;
  margin-top: 10.41667vw; }
#DesignSystem.typography .banner .padding {
    padding-top: 5%;
    padding-bottom: 5%;
    position: relative; }
#DesignSystem.typography .banner .screen {
    position: absolute;
    right: 15px;
    bottom: 20px;
    max-width: 572px;
    width: 29.79167vw; }
@media (max-width: 767.98px) {
      #DesignSystem.typography .banner .screen {
        display: none; } }
#DesignSystem.typography .document .banner {
  background-color: #e43852;
  color: #fff; }
#DesignSystem.typography .tools {
  max-width: 100%; }
#DesignSystem.typography .font-family {
  border-bottom: 1px solid #e1e3e4;
  padding: 5rem 0; }
#DesignSystem.typography .font-family:last-child {
    border-bottom: none; }
#DesignSystem.typography .font-family h4 {
    color: #818a91;
    font-weight: 700;
    margin: 2rem 0 1rem; }
#DesignSystem.typography .font-family .preview {
    font-size: 3.75rem; }
#DesignSystem.typography .font-family .preview.opensans {
      font-family: "Open sans"; }
#DesignSystem.typography .font-family .preview.opensans.regular {
        font-weight: 400; }
#DesignSystem.typography .font-family .preview.opensans.bold {
        font-weight: 700; }
#DesignSystem.typography .font-family .preview.spoqahanssans {
      font-family: "Spoqa Han Sans"; }
#DesignSystem.typography .font-family .preview.spoqahanssans.regular {
        font-weight: 400; }
#DesignSystem.typography .font-family .preview.spoqahanssans.bold {
        font-weight: 700; }
#DesignSystem.typography .font-family .preview.meiryo.regular {
      font-family: "Meiryo"; }
#DesignSystem.typography .font-family .preview.meiryo.bold {
      font-family: "MeiryoBold"; }
#DesignSystem.typography .font-family .preview.futurapt {
      font-family: "futura-pt"; }
#DesignSystem.typography .font-family .preview.futurapt.light {
        font-weight: 300; }
#DesignSystem.typography .font-family .preview.futurapt.book {
        font-weight: 400; }
#DesignSystem.typography .font-family .preview.futurapt.medium {
        font-weight: 500; }
#DesignSystem.typography .font-family .preview.futurapt.demi {
        font-weight: 600; }
#DesignSystem.typography .font-family .preview.futurapt.heavy {
        font-weight: 700; }
#DesignSystem.typography .font-family .preview.futurapt.extrabold {
        font-weight: 800; }
#DesignSystem.typography .font-family .preview.futurapt.bold {
        font-family: "futura-pt-bold";
        font-weight: 700; }
#DesignSystem.typography .font-family .preview.notosanskr {
      font-family: "noto-sans-cjk-kr"; }
#DesignSystem.typography .font-family .preview.notosanskr.demilight {
        font-weight: 300; }
#DesignSystem.typography .font-family .preview.notosanskr.regular {
        font-weight: 400; }
#DesignSystem.typography .font-family .preview.notosanskr.medium {
        font-weight: 500; }
#DesignSystem.typography .font-family .preview.notosanskr.bold {
        font-weight: 700; }
#DesignSystem.typography .font-family .preview.notosanskr.black {
        font-weight: 900; }
@media (max-width: 991.98px) {
      #DesignSystem.typography .font-family .preview {
        margin-bottom: 2rem; } }
#DesignSystem.typography .font-family .btn.download {
    display: inline-block;
    margin-bottom: 20px; }
#DesignSystem.iconography .btn.download {
  width: 280px;
  margin: 40px auto; }
#DesignSystem.iconography .icon-container {
  margin: 0 auto;
  width: 297px;
  padding: 66px 0 0 14px; }
#DesignSystem.iconography .icon-container:after {
    content: "";
    display: block;
    width: 100%;
    clear: both; }
#DesignSystem.iconography .icon-container .icon {
    display: block;
    padding: 0 0 0 14px; }
#DesignSystem.iconography .icon-container .icon.icon-2 {
      padding-top: 47px; }
#DesignSystem.iconography .icon-container .icon.icon-3 {
      padding-top: 40px;
      float: left; }
#DesignSystem.iconography .icon-container .icon.icon-4 {
      float: left;
      padding-top: 40px;
      padding-left: 40px; }
#DesignSystem.iconography .white-mode {
  background-image: url(/static/img/design_system/white-grid.jpg);
  background-position: center top;
  /*background-color: transparent;
      background-size: 40px 40px;
      background-image: linear-gradient(
          0deg,
          transparent 24%,
          $gray-200 25%,
          $gray-200 26%,
          transparent 27%,
          transparent 74%,
          $gray-200 75%,
          $gray-200 76%,
          transparent 77%,
          transparent
        ),
        linear-gradient(
          90deg,
          transparent 24%,
          $gray-200 25%,
          $gray-200 26%,
          transparent 27%,
          transparent 74%,
          $gray-200 75%,
          $gray-200 76%,
          transparent 77%,
          transparent
        );*/ }
#DesignSystem.iconography .dark-mode {
  color: #fff;
  background-image: url(/static/img/design_system/dark-grid.jpg);
  background-position: center top; }
#DesignSystem.sns .examples img {
  padding-top: 70px;
  padding-bottom: 50px;
  max-width: 100%; }
#DesignSystem.sns .examples strong {
  font-size: 20px;
  font-weight: 700; }
#DesignSystem.sns .buttons {
  padding-top: 80px; }
@media (max-width: 767.98px) {
    #DesignSystem.sns .buttons > div {
      padding-bottom: 30px; } }
#DesignSystem.sns .buttons a.btn {
    width: 287px; }
#DesignSystem.sns .buttons .history {
    padding-top: 20px;
    color: #818a91;
    font-size: 0.75rem; }
#DesignSystem.document .col-md-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
#DesignSystem.document .card {
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 50px 20px 30px;
  border: none;
  background-color: #f9f9f9;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
#DesignSystem.document .card h3 {
    margin-bottom: 1rem; }
#DesignSystem.document .card img {
    border-bottom: none;
    padding-bottom: 30px;
    width: auto;
    height: 160px; }
#DesignSystem.document .btn.download {
  margin: 40px 0 60px 0; }
#DesignSystem .fb-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
@media (max-width: 767.98px) {
    #DesignSystem .fb-links {
      display: block; } }
.flash-message {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9999;
  width: 450px;
  background: #fff;
  color: #fff;
  text-align: left;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
.flash-message .error {
    background: #f2dede;
    color: #a94442;
    border-color: #ebccd1 !important; }
.flash-message .error button {
      color: #a94442; }
.flash-message .success {
    background: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6 !important; }
.flash-message .success button {
      color: green; }
.flash-message .warning {
    background: #fcf8e3;
    color: #8a6d3b;
    border-color: #faebcc !important; }
.flash-message .warning button {
      color: #8a6d3b; }
.flash-message .flash__message {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 1rem;
    font-family: Oxygen, sans-serif;
    margin-bottom: 5px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 5px;
    border: 2px solid black; }
.flash-message .flash__close-button {
    margin-left: auto;
    background: none;
    border: 0;
    font-size: 2.1875rem;
    cursor: pointer;
    color: #fff;
    font-weight: 100; }
.alert-success {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 24px;
  z-index: 9999999;
  padding: 4.75rem 6.25rem;
  opacity: 1;
  background-color: #ffffff;
  -webkit-box-shadow: 5px 5px 50px rgba(52, 65, 76, 0.15);
          box-shadow: 5px 5px 50px rgba(52, 65, 76, 0.15);
  border-radius: 0px 0px 40px 0px;
  border-top: 4px solid #2a3642;
  color: black;
  font-family: Futura PT;
  font-style: normal;
  font-weight: 600;
  font-size: 22px; }
@media (max-width: 767.98px) {
    .alert-success {
      top: 0;
      right: 0;
      left: unset;
      padding: 0.75rem 1.25rem;
      font-size: 1em;
      -webkit-transform: none;
              transform: none; } }
@media (max-width: 991.98px) {
  .flash-message {
    top: 0;
    right: 0;
    width: 100%; }
    .flash-message .flash__message {
      border: none;
      border-radius: 0; } }
input[type=text], .custom-select {
  border: none;
  border-bottom: 1px solid #d1d4d7;
  margin-right: 10px; }
input[type=text]:focus, .custom-select:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom: 1px solid #818a91; }
.form-inline {
  margin: 0; }
.form-inline label {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
.board {
  float: left;
  margin: 0 15px 15px 0;
  position: relative; }
@media (max-width: 767.98px) {
    .board {
      width: 100%; } }
.board:hover .btn.delete {
    opacity: 1; }
.board .btn {
    width: auto; }
.board h3 {
    display: inline-block;
    font-size: 1.25rem;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 2px; }
@media (max-width: 767.98px) {
      .board h3 {
        margin: 10px 0;
        max-width: none; } }
.board h3:after {
      font-family: "icomoon";
      content: "\E905";
      font-size: 0.7em;
      margin-left: 10px;
      color: #818a91;
      opacity: 0; }
.board h3:hover:after {
      opacity: 1; }
.board input[type="text"] {
    font-size: 1.25rem; }
.board input[type="text"]:focus {
      outline: none; }
.board .btn {
    background: none;
    font-size: 1rem;
    padding: 0;
    margin-right: 5px; }
.board .btn .icon-checkmark {
      color: #4dbd74; }
.board .btn .icon-cross {
      color: #818a91;
      font-size: 0.75em; }
.board .btn.delete {
      font-size: 0.9rem;
      color: #f86c6b;
      padding: 3px 0;
      opacity: 0;
      display: block; }
@media (max-width: 767.98px) {
        .board .btn.delete {
          opacity: 1; } }
.board .sortable-ghost {
    border: 2px dashed #2a3642; }
.board .list-group {
    min-width: 200px;
    width: 200px;
    min-height: 30px;
    padding: 3px;
    border: 1px dashed #d1d4d7; }
@media (max-width: 767.98px) {
      .board .list-group {
        width: 100%; } }
.board .list-group:after {
      content: '+';
      color: #d1d4d7;
      text-align: center;
      width: 100%;
      font-size: 2rem;
      position: absolute;
      bottom: 28px;
      left: 0;
      pointer-events: none; }
.board .list-group span {
      min-height: 30px;
      padding-bottom: 30px; }
.board .list-group li {
      display: block;
      margin: 0 0 10px 0; }
.board .list-group li:last-child {
        margin: 0; }
.board .list-group a {
      width: 100%;
      display: block;
      color: #2a3642;
      padding: 5px 10px;
      white-space: normal; }
.board .list-group a:hover {
        text-decoration: none;
        color: #fff;
        background: #2a3642; }
.btn-grey {
  border-color: #2a3642;
  opacity: 0.5;
  margin: 0 10px; }
.btn-grey:hover {
    background: #e43852;
    color: #fff;
    border-color: #e43852;
    opacity: 1; }
@media (max-width: 767.98px) {
    .btn-grey {
      margin: 10px 0; } }
.btn.underline {
  text-transform: uppercase;
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-size: 1.125rem;
  position: relative;
  border: none;
  border-bottom: 1px solid #d1d4d7;
  padding: 0.75rem;
  margin-bottom: 10%; }
@media (max-width: 767.98px) {
    .btn.underline {
      font-size: 1rem; } }
.btn.underline:after {
    content: "";
    width: 0;
    height: 1px;
    background-color: #2a3642;
    position: absolute;
    bottom: -1px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: width 0.5s ease;
    transition: width 0.5s ease; }
.btn.underline.active:after, .btn.underline:hover:after {
    width: 100%; }
.btn.download, .btn.link {
  text-align: left;
  font-size: 1rem;
  background-color: #f9f9f9;
  color: #2a3642;
  padding: 15px 20px;
  width: 100%;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease; }
.btn.download:after, .btn.link:after {
    font-family: "icomoon";
    content: "\E912";
    float: right; }
.btn.download.webfont:after, .btn.link.webfont:after {
    content: "\E911"; }
.btn.download.webfont:hover, .btn.link.webfont:hover {
    color: #fff; }
.btn.download:hover, .btn.link:hover {
    background-color: #e43852;
    color: #fff;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); }
.btn.link:after {
  content: "\E915"; }
.btn.go {
  text-align: left;
  font-size: 1rem;
  background-color: #f9f9f9;
  color: #2a3642;
  padding: 15px 20px;
  width: 100%;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease; }
.btn.go:after {
    font-family: "icomoon";
    content: "\E90A";
    float: right; }
.btn.go:hover {
    background-color: #e43852;
    color: #fff;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); }
.meetings {
  position: fixed;
  bottom: -150px;
  left: 0;
  right: 0;
  min-height: 290px;
  border-radius: 5.20833vw 5.20833vw 0 0;
  -webkit-box-shadow: 10px -20px 70px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 10px -20px 70px 0px rgba(0, 0, 0, 0.06);
  padding: 25px 20px 0;
  z-index: 9999;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: bottom 0.5s ease;
  transition: bottom 0.5s ease;
  background-color: #fff;
  cursor: pointer; }
@media (min-width: 768px) {
    .meetings.no-meeting {
      bottom: -200px; } }
@media screen and (max-height: 760px) {
    .meetings {
      overflow: auto;
      bottom: -230px; } }
@media screen and (min-width: 1024px) and (max-height: 750px) {
    .meetings {
      overflow: auto;
      bottom: -230px; } }
@media (max-width: 767.98px) {
    .meetings {
      height: 440px;
      bottom: -370px; } }
.meetings.open {
    bottom: 0; }
.meetings.open i {
      -webkit-transform: translate(-50%) scale(1);
              transform: translate(-50%) scale(1); }
.meetings i {
    position: absolute;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
            transform: translateX(-50%) scale(-1);
    color: #d1d4d7; }
@media (max-width: 767.98px) {
      .meetings i {
        top: 27px;
        left: auto;
        right: 25px;
        -webkit-transform: translateX(-50%) scale(-1);
                transform: translateX(-50%) scale(-1); } }
.meetings .title {
    font-size: 1.25rem; }
@media (max-width: 767.98px) {
      .meetings .title {
        font-size: 1rem; } }
.meetings .title span {
      font-size: 0.833em;
      color: #fff;
      background-color: #e43852;
      border-radius: 50%;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      position: relative;
      top: -10px; }
@media (max-width: 767.98px) {
        .meetings .title span {
          width: 25px;
          height: 25px;
          line-height: 25px; } }
.meetings .no-meeting {
    margin-top: 20px; }
.meetings .timeline {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 20px; }
@media (max-width: 767.98px) {
      .meetings .timeline {
        overflow-y: auto;
        overflow-x: visible;
        height: 370px; }
        .meetings .timeline::-webkit-scrollbar {
          width: 0; } }
.meetings .timeline::-webkit-scrollbar {
      height: 4px; }
.meetings .timeline::-webkit-scrollbar-track {
      background: #fff; }
.meetings .timeline::-webkit-scrollbar-thumb {
      background: #e1e3e4; }
.meetings .timeline::-webkit-scrollbar-thumb:hover {
      background: #d1d4d7; }
.meetings .timeline ul {
      margin: 10px 0 0 0;
      padding: 0 0 0 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
              align-items: stretch;
      border-top: 1px solid #d1d4d7; }
@media (max-width: 767.98px) {
        .meetings .timeline ul {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          border-top: none;
          padding: 0;
          position: relative; }
          .meetings .timeline ul:before {
            content: "";
            width: 1px;
            height: 100%;
            background-color: #d1d4d7;
            position: absolute;
            left: 6px;
            top: 0; } }
.meetings .timeline li {
      border-top: 1px solid #d1d4d7;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      position: relative;
      padding: 20px 40px 0 0;
      min-width: 180px;
      width: 180px;
      top: -1px; }
@media (max-width: 767.98px) {
        .meetings .timeline li {
          width: 100%;
          padding: 20px 40px 0 20px;
          border: none; } }
.meetings .timeline li:before {
        content: "";
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #d1d4d7;
        display: block;
        position: absolute;
        top: -7px;
        left: 0; }
@media (max-width: 767.98px) {
          .meetings .timeline li:before {
            top: 26px; } }
.meetings .timeline li.seouloffice-4-meeting:before {
        background-color: #f36375; }
.meetings .timeline li.seouloffice-4-studio:before {
        background-color: #b58dd6; }
.meetings .timeline li.seouloffice-4-creative:before {
        background-color: #516478; }
.meetings .timeline li.seouloffice-3-3f:before {
        background-color: #61c7c7; }
.meetings .timeline li.seouloffice-phone-booth:before {
        background-color: #61c7c7; }
.meetings .timeline li.current {
        background-color: #d1d4d7; }
.meetings .timeline li .time {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.2; }
.meetings .timeline li .room {
        margin-top: 20px;
        font-size: 1.125rem;
        line-height: 1.2;
        font-weight: 450; }
.meetings .timeline li .description {
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
.meetings .timeline li .description:hover {
          text-overflow: clip;
          white-space: normal; }
.meetings .timeline li .organizer {
        font-size: 0.75rem;
        font-weight: 600;
        background-color: #f4f5f6;
        border-radius: 20px;
        padding: 3px 7px;
        margin-top: 15px; }
.meetings .timeline li .organizer span {
          text-transform: capitalize; }
.partner-modal > .modal-dialog {
  max-width: 700px; }
.partner-modal > .modal-dialog > .modal-content {
    border-radius: 0 0 20px 0;
    border: none;
    -webkit-box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.1); }
.partner-modal > .modal-dialog > .modal-content > .modal-backdrop {
      background-color: none; }
@media (max-width: 767px) {
  .modal {
    padding: 0 !important; }
  .modal.show .modal-dialog {
    margin: 0;
    padding: 0; }
  .modal-body {
    min-height: 100vh; }
  #__BVID__9___BV_modal_outer_ {
    z-index: 99999; }
  .partner-modal > .modal-dialog > .modal-content {
    border-radius: 0; }
  .partner-contact {
    display: none; }
  .modal-dialog-centered {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; } }
.p-modal-container {
  padding: 0 16px; }
.p-modal-body {
  margin: 10px;
  padding-bottom: 0; }
.partner-summary-info {
  position: relative;
  text-align: center; }
.partner-summary-info h3 {
    color: #e43852;
    margin-top: 20px; }
.p-key-info {
  margin: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
.p-key-info .pipe {
    padding: 0 12px; }
@media (max-width: 767px) {
  .p-key-info {
    display: block; }
  .pipe {
    display: none; } }
.p-description {
  margin: 0; }
.partner-contact {
  background: #f4f5f6;
  padding: 8px; }
.partner-contact p {
    margin: 0; }
.partner-detail-info {
  margin-top: 20px; }
.partner-detail-info p {
    margin: 0; }
@media (min-width: 767px) {
  .detail-col {
    padding-left: 0;
    padding-right: 5px; } }
@media (max-width: 767px) {
  .detail-col {
    padding: 0;
    margin-bottom: 20px; } }
.partner-service-info {
  -webkit-box-shadow: 0px 3px 10px 0px #dddddd;
          box-shadow: 0px 3px 10px 0px #dddddd;
  border-left: solid 5px #e43852;
  padding: 10px;
  height: 100%;
  position: relative; }
.partner-service-info h5 {
    color: #e43852; }
.partner-relation-info {
  -webkit-box-shadow: 0px 3px 10px 0px #dddddd;
          box-shadow: 0px 3px 10px 0px #dddddd;
  border-left: solid 5px #000000;
  padding: 10px;
  position: relative; }
@media (min-width: 767px) {
  .detail-col2 {
    padding-left: 5px;
    padding-right: 0; } }
@media (max-width: 767px) {
  .detail-col2 {
    padding: 0; } }
button#modalclose {
  position: absolute;
  right: -5px;
  top: -10px;
  font-size: 32px;
  padding: 0; }
.p-icons {
  padding-right: 12px;
  font-size: 20px; }
.p-modal-icons {
  padding: 0;
  font-size: 16px; }
.p-modal-icons a {
    color: black; }
.p-modal-icons a:hover {
      text-decoration: none; }
/* Create and move to tabs.scss */
.tab-content:focus,
.tab-pane:focus {
  outline: none;
  border: none; }
.nav-tabs {
  margin: 0 auto;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none;
  max-width: 33.3333%; }
@media (max-width: 767.98px) {
    .nav-tabs {
      max-width: 91.66667%; } }
.nav-tabs .nav-item {
    text-align: center;
    width: 100% !important;
    margin-bottom: 10%; }
.nav-tabs .nav-link {
    border: none;
    border-bottom: 1px solid #d1d4d7 !important;
    font-size: 1.125rem;
    padding: 0.75rem;
    margin-bottom: 10%;
    position: relative;
    color: #2a3642; }
@media (max-width: 767.98px) {
      .nav-tabs .nav-link {
        font-size: 0.75rem;
        padding: 0.5rem; } }
.nav-tabs .nav-link:after {
      content: "";
      width: 0;
      height: 1px;
      background-color: #2a3642;
      position: absolute;
      bottom: -1px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      -webkit-transition: width 0.5s ease;
      transition: width 0.5s ease; }
.nav-tabs .nav-link.active, .nav-tabs .nav-link:hover {
      background-color: transparent; }
.nav-tabs .nav-link.active:after, .nav-tabs .nav-link:hover:after {
        width: 100%; }
.drop-filter {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f4f5f6;
  min-width: 100%;
  border-top: 2px solid #d1d4d7;
  font-weight: 400;
  text-align: left;
  padding: 15px 10px;
  -webkit-box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.1); }
.drop-filter label {
    display: block;
    white-space: nowrap; }
.drop-filter label:last-child {
      margin-bottom: 0; }
.drop-filter .custom-checkbox {
    margin-bottom: 13px; }
.drop-filter .custom-checkbox:last-child {
      margin-bottom: 0; }
.drop-filter .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #2a3642 !important;
      background-color: #2a3642; }
.drop-filter .custom-control-label {
    padding-left: 5px; }
.drop-filter .custom-control-label:before, .drop-filter .custom-control-label:after {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      top: 0; }
.drop-filter .custom-control-label:before {
      background-color: #fff;
      border: 1px solid #d1d4d7 !important; }
.drop-filter .custom-control-label:after {
      left: -24px; }
